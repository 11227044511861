import React from 'react';
import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core';

export interface LinkModalProps {
  isOpen: boolean;
  href?: string;
  htmlContent?: string;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2, 1),
    width: '45%',
    height: '70%',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
}));

const LinkModal: React.FC<LinkModalProps> = ({ onClose, isOpen, href, htmlContent, ...modalProps }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            {href && <iframe title="3D Form" src={href} className={classes.iframe} />}
            {htmlContent && (
              <iframe
                title="3D Form"
                className={classes.iframe}
                // dangerouslySetInnerHTML={{ __html: htmlContent ?? '' }}
                srcDoc={htmlContent}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default LinkModal;
