export interface MenuBlockChildrenInfoClickProps {
  dispatch: (v: any) => void;
}

export enum InstallmentType {
  PAY_WITH_NO_INSTALLMENT = 1,
  PAY_WITH_INSTALLMENT = 2,
}

export const formatTRYSetting = Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',
});
