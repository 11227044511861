import { createSlice } from '@reduxjs/toolkit';
import {
  showToast,
  hideToast,
  showBalancePaymentDialog,
  toggleDrawer,
  toggleMobileDrawer,
  showFullscreenLoading,
  setPaymentTransactionId,
  setPaymentAmount,
  setIsPackagePayment,
  setIsMembershipPayment,
  setInstallmentType,
  setPaymentOrderType,
} from './actions';
import PaymentTypeEnum, { CoreState, PaymentDialogProps, ToastProps } from './types';

const defaultToastProps: ToastProps = {
  autoHideDuration: 3000,
  message: '',
};

const defaultPaymentDialogProps: PaymentDialogProps = {
  show: false,
  typeId: PaymentTypeEnum.BALANCE,
};

const initialCoreState: CoreState = {
  toastProps: defaultToastProps,
  showToast: false,
  isBalancePaymentDialogOpen: false,
  isDrawerOpen: true,
  unreadCount: 0,
  unreadAnnouncementCount: 0,
  isMobileDrawerOpen: false,
  showFullscreenLoading: false,
  paymentDialogProps: defaultPaymentDialogProps,
  paymentTransactionId: undefined,
  isPackagePayment: false,
  isMembershipPayment: false,
  installmentType: undefined,
  paymentOrderType: undefined,
};

export const coreSlice = createSlice({
  name: 'core',
  initialState: initialCoreState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(showToast, (state, action) => {
      state.toastProps = { ...defaultToastProps, ...action.payload };
      state.showToast = true;
    });

    builder.addCase(hideToast, (state) => {
      state.showToast = false;
    });

    builder.addCase(showBalancePaymentDialog, (state, action) => {
      state.isBalancePaymentDialogOpen = action.payload.show;
      state.paymentDialogProps = action.payload;
    });

    builder.addCase(toggleDrawer, (state, action) => {
      state.isDrawerOpen = action.payload;
    });

    builder.addCase(toggleMobileDrawer, (state, action) => {
      state.isMobileDrawerOpen = action.payload;
    });

    builder.addCase(showFullscreenLoading, (state, action) => {
      state.showFullscreenLoading = action.payload;
    });

    builder.addCase(setPaymentTransactionId, (state, action) => {
      state.paymentTransactionId = action.payload;
    });

    builder.addCase(setIsPackagePayment, (state, action) => {
      state.isPackagePayment = action.payload;
    });

    builder.addCase(setIsMembershipPayment, (state, action) => {
      state.isMembershipPayment = action.payload;
    });

    builder.addCase(setInstallmentType, (state, action) => {
      state.installmentType = action.payload;
    });

    builder.addCase(setPaymentAmount, (state, action) => {
      state.paymentAmount = action.payload;
    });

    builder.addCase(setPaymentOrderType, (state, action) => {
      state.paymentOrderType = action.payload;
    });
  },
});

export default coreSlice.reducer;
