import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
  OutlinedInput,
  InputAdornment,
  Typography,
} from '@material-ui/core';

import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Lock as LockIcon, VpnKey as VpnKeyIcon } from '@material-ui/icons';
import { Formik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from 'reactmuicommon';
import { Api, showToast } from 'core';
import { useDispatch } from 'react-redux';
import style from '../styles/loginPage.module.scss';
import { ResetPasswordRequest } from '../models';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '8px',
      textAlign: 'center',
    },
  };
});

const ResetPasswordPage = () => {
  const history = useHistory();
  const resetPasswordStyle = useStyles();
  const dispatch = useDispatch();
  const { token }: any = useParams();

  const [showLoading, setShowLoading] = React.useState(false);

  const resetPasswordSchema = yup.object({
    password: yup
      .string()
      .required('Boş olamaz')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        'Şifreniz minimum 8 karakterden oluşmalı, en az bir harf ve en az bir rakam içermelidir.',
      ),
    password2: yup
      .string()
      .required('Parola Tekrarı Boş Olamaz')
      .oneOf([yup.ref('password')], 'Parolalar Eşleşmiyor')
      .required('Boş olamaz'),
  });

  const initialValues = {
    password: '',
    password2: '',
  };

  const resetPasswordSubmit = async (data: any) => {
    setShowLoading(true);
    const request = { token: token.toString(), password: data.password } as ResetPasswordRequest;
    const response = await Api.Auth.resetPassword(request);

    if (!response.isSuccess) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
      return;
    }

    dispatch(
      showToast({
        message: `Şifreniz başarıyla değiştirilmiştir. Yeni şifreniz ile giriş yapabilirsiniz.`,
      }),
    );
    history.push('/auth/login');
    setShowLoading(false);
  };

  return (
    <Container maxWidth="xl" className={style.container}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={style.loginContainerGrid}
      >
        <Grid item xs={12} md={3}>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Card className={style.loginCard}>
              <Typography variant="h5" component="h2" className={resetPasswordStyle.title}>
                Şifre Sıfırlama
              </Typography>
              <Formik
                onSubmit={resetPasswordSubmit}
                validationSchema={resetPasswordSchema}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
              >
                {({ handleSubmit, handleChange, errors }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <CardContent>
                      <Box mt={2}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel htmlFor="email">Şifre</InputLabel>
                              <OutlinedInput
                                id="password"
                                type="password"
                                label="Şifre"
                                name="password"
                                onChange={handleChange}
                                error={errors.password !== null}
                                placeholder="Parola"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <LockIcon />
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText>{errors.password}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel htmlFor="password">Şifre</InputLabel>
                              <OutlinedInput
                                id="password2"
                                type="password"
                                name="password2"
                                error={errors.password2 !== null}
                                onChange={handleChange}
                                placeholder="Parola tekrar"
                                label="Şifre"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <LockIcon />
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText>{errors.password2}</FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <LoadingButton
                              color="primary"
                              type="submit"
                              variant="contained"
                              disableElevation
                              className="w-100"
                              startIcon={<VpnKeyIcon />}
                              showLoading={showLoading}
                            >
                              Şifremi değiştir
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </form>
                )}
              </Formik>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResetPasswordPage;
