/* eslint-disable no-console */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect, useDispatch } from 'react-redux';
import { AccountCircle, LiveHelp, Lock as LockIcon, VpnKey as VpnKeyIcon, ArrowBack, Email } from '@material-ui/icons';
import { Api, CollapsableAlert, showToast } from 'core';
import { LoadingButton } from 'reactmuicommon';
import { useLocation } from 'react-router';
import { loginActionThunk } from '../store/thunk';
import style from '../styles/loginPage.module.scss';
import { logout, setToken, setUserInfo } from '../store';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '8px',
      textAlign: 'center',
    },
  };
});

const loginFormSchema = yup.object({
  email: yup.string().email('Email formatı yanlış!').required('Boş olamaz!'),
  password: yup.string().required('Boş olamaz!'),
});

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const LoginPage = () => {
  const loginPageStyle = useStyles();
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showServerError, setShowServerError] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState<string>('');

  const dispatch = useDispatch();
  const query = useQuery();

  const token = query.get('token');

  const initialValues = {
    email: '',
    password: '',
  };

  React.useEffect(() => {
    if (!token) {
      return;
    }
    dispatch(setToken(token));
    Api.Auth.getUserInfo()
      .then((response) => {
        if (!response.isSuccess || !response.result) {
          console.error(`Token is not valid!!`, token);
          dispatch(logout());
          dispatch(
            showToast({
              message: `Token is not valid!`,
              severity: 'error',
            }),
          );
          return;
        }

        dispatch(setUserInfo(response.result));
      })
      .catch((err) => {
        console.error(`Unexpected Error`, err);
        dispatch(logout());
        dispatch(
          showToast({
            message: `Unexpected Error: ${err.message}`,
            severity: 'error',
          }),
        );
      });
  }, []);

  const onLoginFormSubmit = async (data: any) => {
    setShowLoading(true);
    setShowError(false);
    setShowServerError(false);
    try {
      const response = await Api.Auth.login({
        email: data.email,
        password: data.password,
      });

      const loginResponse = response;
      if (loginResponse.isSuccess && loginResponse.result) {
        dispatch(setUserInfo(loginResponse.result?.userInfo));
        dispatch(setToken(loginResponse.result?.token));
      } else {
        setShowError(true);
      }
    } catch (e) {
      const errCode = e.response?.data?.statusCode;
      if (errCode && errCode === 401) {
        setShowError(true);
      } else {
        setShowServerError(true);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const handleEmailChange = (e: any) => {
    setSendEmail(e.target.value);
  };

  const submitForgotPassword = async () => {
    setShowLoading(true);

    try {
      const response = await Api.Auth.sendEmailResetPassword(sendEmail);
      if (response.isSuccess) {
        setForgotPassword(false);
        dispatch(
          showToast({
            message: 'Şifre sıfırlama isteği mail adresinize gönderilmiştir. Lütfen mailinizi kontrol ediniz.',
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `${response.errorMessage ?? 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    } catch (e) {
      dispatch(
        showToast({
          message: `${e.errorMessage ?? 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    }

    setShowLoading(false);
  };

  return (
    <Container maxWidth="xl" className={style.container}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={style.loginContainerGrid}
      >
        <Grid item xs={12} md={3}>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Card className={style.loginCard}>
              <Typography variant="h5" component="h2" className={loginPageStyle.title}>
                Giriş Yapın
              </Typography>
              <CollapsableAlert show={showError} severity="error">
                Kullanıcı adı veya Şifreniz Yanlış
              </CollapsableAlert>
              <CollapsableAlert show={showServerError} severity="error">
                Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.
              </CollapsableAlert>
              {!forgotPassword && (
                <Formik
                  onSubmit={onLoginFormSubmit}
                  validationSchema={loginFormSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={initialValues}
                >
                  {({ handleSubmit, handleChange, errors }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <CardContent>
                        <Box mt={2}>
                          <Grid container direction="column" spacing={1}>
                            <Grid item xs={12}>
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <OutlinedInput
                                  id="email"
                                  type="text"
                                  label="Email"
                                  name="email"
                                  onChange={handleChange}
                                  error={errors.email !== null}
                                  placeholder="Email"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <AccountCircle />
                                    </InputAdornment>
                                  }
                                />
                                <FormHelperText error>{errors.email}</FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="password">Şifre</InputLabel>
                                <OutlinedInput
                                  id="password"
                                  type="password"
                                  name="password"
                                  error={errors.password !== null}
                                  onChange={handleChange}
                                  placeholder="Parola"
                                  label="Şifre"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <LockIcon />
                                    </InputAdornment>
                                  }
                                />
                                <FormHelperText error>{errors.password}</FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <LoadingButton
                                color="primary"
                                type="submit"
                                variant="contained"
                                disableElevation
                                fullWidth
                                startIcon={<VpnKeyIcon />}
                                showLoading={showLoading}
                              >
                                Giriş Yap
                              </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                disableElevation
                                fullWidth
                                color="primary"
                                startIcon={<LiveHelp />}
                                onClick={() => {
                                  setForgotPassword(true);
                                }}
                              >
                                Şifremi Unutttum
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </form>
                  )}
                </Formik>
              )}

              {forgotPassword && (
                <CardContent>
                  <Box>
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="email">Email</InputLabel>
                          <OutlinedInput
                            id="email"
                            type="text"
                            label="Email"
                            name="email"
                            placeholder="Email"
                            onChange={handleEmailChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <LoadingButton
                          color="primary"
                          variant="contained"
                          disableElevation
                          fullWidth
                          startIcon={<Email />}
                          onClick={submitForgotPassword}
                          showLoading={showLoading}
                        >
                          Şifremi sıfırla
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          disableElevation
                          fullWidth
                          color="primary"
                          startIcon={<ArrowBack />}
                          onClick={() => {
                            setForgotPassword(false);
                          }}
                        >
                          Geri dön
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              )}
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (email: string, password: string) => dispatch(loginActionThunk({ email, password })),
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
