import { FormControlLabel, Checkbox, FormControl, Box, FormHelperText } from '@material-ui/core';
import React from 'react';

export interface LabeledCheckboxProps {
  checked?: boolean;
  onChange?: (event: any) => void;
  label: string;
  name?: string;
  color?: 'primary' | 'secondary' | 'default';
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  disabled?: boolean;
}

const LabeledCheckbox: React.FC<LabeledCheckboxProps> = ({
  checked,
  onChange,
  label,
  name,
  color,
  error,
  helperText,
  readOnly,
  disabled,
}) => {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            name={name}
            color={color || 'primary'}
            readOnly={readOnly}
          />
        }
        label={label}
      />
      <FormHelperText>
        <Box color={error ? 'error.main' : 'text.disabled'} component="span">
          {helperText}
        </Box>
      </FormHelperText>
    </FormControl>
  );
};

export default LabeledCheckbox;
