import React from 'react';
import { Switch } from 'react-router-dom';

interface SubscriptionSwitchProps {
  match: any;
  subscriptionLevel: number;
  children: any;
}

const SubscriptionSwitch: React.FC<SubscriptionSwitchProps> = ({ children }) => {
  return <Switch>{children}</Switch>;
};

export default SubscriptionSwitch;
