import moment from 'moment';
import React from 'react';
import {
  Api,
  ConfirmDialog,
  exportToExcel,
  exportToPdf,
  GetPaymentTransactionRefundsRequestDto,
  getPaymentTransactionRefundStatusEnumString,
  getSortingQuery,
  PaginationQuery,
  PaymentSourceEnum,
  PaymentTransactionRefund,
  PaymentTransactionRefundStatusEnum,
  showToast,
  TranslatedMaterialTable,
} from 'core';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router';

const WaitingRefundApprovalListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState<PaymentTransactionRefundStatusEnum[]>([
    PaymentTransactionRefundStatusEnum.WAITING_ADMIN_APPROVAL,
    PaymentTransactionRefundStatusEnum.APPROVED_BY_ADMIN,
  ]);
  const [selectedTransactionRefund, setSelectedTransactionRefund] = React.useState<PaymentTransactionRefund | null>(
    null,
  );
  const [showReverseTransactionDialog, setShowReverseTransactionDialog] = React.useState(false);
  const [reLoadTable, setReLoadTable] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const actions: any[] = [
    (rowData: PaymentTransactionRefund) => ({
      icon: 'check',
      tooltip: 'Ödemeyi İptal / İade Et',
      disabled:
        rowData.status === PaymentTransactionRefundStatusEnum.APPROVED_BY_ADMIN ||
        rowData.status === PaymentTransactionRefundStatusEnum.REJECTED_BY_ADMIN,
      onClick: (event: any, data: PaymentTransactionRefund) => {
        setSelectedTransactionRefund(data);
        setShowReverseTransactionDialog(true);
      },
    }),
  ];

  const getColumns = (): any[] => {
    return [
      {
        title: 'İşlem No',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'paymentTransaction.transactionId',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'Bayi Adı',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'paymentTransaction.user.agentName',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'İptal/İade Tutarı',
        field: 'amount',
        render: (value: any) => value.amount?.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Müşteri Adı',
        field: 'paymentTransaction.cardHolder',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Brüt Tutar',
        field: 'paymentTransaction.grossAmount',
        render: (value: any) => value.paymentTransaction.grossAmount?.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Net Tutar',
        field: 'paymentTransaction.netAmount',
        render: (value: any) => value.paymentTransaction.netAmount.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'İade Edilebilecek Toplam Tutar',
        field: 'paymentTransaction.refundableAmount',
        render: (value: any) => value.paymentTransaction.refundableAmount.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Durum',
        render: (value: any) =>
          getPaymentTransactionRefundStatusEnumString(value.status as PaymentTransactionRefundStatusEnum),
        field: 'status',
        type: 'string',
        cellStyle: { whiteSpace: 'nowrap' },
        searching: false,
        filtering: false,
      },
      {
        title: 'İptal/İade Talep Tarihi',
        type: 'date',
        render: (value: any) => moment(value.createdDate).format('DD/MM/YYYY HH:mm'),
        field: 'createdDate',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
    ];
  };

  const handleDialogClose = () => {
    setShowReverseTransactionDialog(false);
  };

  const approveTransactionRefund = async () => {
    if (selectedTransactionRefund) {
      handleDialogClose();
      setShowLoading(true);
      if (selectedTransactionRefund.paymentTransaction?.providerType == PaymentSourceEnum.PAYNET) {
        const response = await Api.Paynet.adminApproveReverseTransactionRequest(
          selectedTransactionRefund.paymentTransactionId,
          selectedTransactionRefund.id,
        );
        if (response.isSuccess) {
          setReLoadTable(true);
          dispatch(
            showToast({
              message: `İptal/İade talebi başarıyla onaylandı!`,
            }),
          );
        } else {
          dispatch(
            showToast({
              message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
              severity: 'error',
            }),
          );
        }
      }
    }
    setShowReverseTransactionDialog(false);
    setShowLoading(false);
    setSelectedTransactionRefund(null);
    setReLoadTable(false);
  };

  const getPaymentTransactionRefunds = async (query: any) => {
    const { filters, orderBy, orderDirection, page, pageSize, search } = query;
    try {
      setShowLoading(true);
      const requestDto = {
        paginationQuery: { page, pageSize } as PaginationQuery,
        sortingQuery: getSortingQuery(orderBy, orderDirection),
        statuses: statusFilter,
        searchText: search,
      } as GetPaymentTransactionRefundsRequestDto;

      const response = await Api.getPaymentTransactionRefunds(requestDto);
      if (response.isSuccess) {
        const { result } = response;
        return {
          data: result?.data ?? [],
          page,
          totalCount: result?.totalCount ?? 0,
        };
      }
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${e.message ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }

    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  };

  return (
    <React.Fragment>
      <br />
      <br />
      <IconButton onClick={() => history.goBack()}>
        <ArrowBack />
      </IconButton>
      {!reLoadTable && (
        <TranslatedMaterialTable
          title="İptal/İade Onayı Bekleyen Ödeme Listesi"
          columns={getColumns()}
          data={getPaymentTransactionRefunds}
          actions={actions}
          isLoading={showLoading}
          options={{
            filtering: true,
            exportAllData: true,
            exportButton: true,
            exportCsv: exportToExcel,
            // @ts-ignore
            exportPdf: exportToPdf,
          }}
        />
      )}
      <ConfirmDialog
        title="İptal/İade Talep Onayı"
        open={showReverseTransactionDialog}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={approveTransactionRefund}
        handleClose={handleDialogClose}
      >
        {selectedTransactionRefund && (
          <div>
            <b>{selectedTransactionRefund?.paymentTransaction?.transactionId}</b> numaralı işlemin İptal/İade talebini
            onaylıyor musunuz? <br /> <b>Tutar: {selectedTransactionRefund.amount.toFixed(2)} TL</b>
            <br />
          </div>
        )}
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default WaitingRefundApprovalListPage;
