import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import moment from 'moment';
import { DateRangePickerDialog } from 'core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DateRange } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    alertMessage: {
      width: '100%',
    },
    alertRoot: {
      alignItems: 'center',
    },
    button: {
      backgroundColor: theme.palette.type === 'dark' ? 'black' : '#E8F4FD',
      color: theme.palette.type === 'dark' ? '#96d6FA' : '#0D3C61',
      height: '60px',
      fontWeight: 'bold',
      padding: theme.spacing(2),
    },
  }),
);

interface DateRangeSelectRowProps {
  selectionRange: any;
  onSelectionRange: (value: any) => void;
}
const DateRangeSelectRow: React.FC<DateRangeSelectRowProps> = ({
  selectionRange,
  onSelectionRange: setSelectionRange,
}) => {
  const classes = useStyles();
  const [showDateRangeDialog, setShowDateRangeDialog] = React.useState(false);
  return (
    <React.Fragment>
      <DateRangePickerDialog
        showDateRangePickerDialog={showDateRangeDialog}
        setShowDateRangePickerDialog={setShowDateRangeDialog}
        setSelectionRange={setSelectionRange}
        selectionRange={selectionRange}
      />
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid item xs={12} md={4}>
          <Button
            variant="outlined"
            className={classes.button}
            size="large"
            startIcon={<DateRange />}
            fullWidth
            onClick={() => {
              setShowDateRangeDialog(true);
            }}
          >
            Tarih Filtreleme
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
          <Alert severity="info" classes={{ message: classes.alertMessage, root: classes.alertRoot }}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={6}>
                <Typography variant="h6">
                  Başlangıç Tarihi :{' '}
                  <Hidden mdUp>
                    <br />
                  </Hidden>{' '}
                  {moment(selectionRange.startDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">
                  Bitiş Tarihi :{' '}
                  <Hidden mdUp>
                    <br />
                  </Hidden>
                  {moment(selectionRange.endDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Alert>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DateRangeSelectRow;
