import React from 'react';
import { Card, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

interface InfoCardProps {
  text: string;
  title: string;
  icon?: any;
  className?: string;
  subtitle?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  backgroundColor?: string;
}

const useStyle = makeStyles(() => {
  return {
    widthAuto: {
      width: 'auto',
    },
    widthFull: {
      width: '100%',
    },
    InfoCard: {
      borderRadius: '0px',
      position: 'relative',
      height: '130px',
      '& .iconStyle': {
        color: '#000000',
        opacity: 0.3,
        right: '5px',
        transition: 'transform .5s',
        '& .MuiSvgIcon-root': {
          fontSize: '70px',
          borderRadius: '0px',
        },
      },
      '&:hover': {
        '& .iconStyle': {
          transform: 'scale3d(1.20, 1.20, 1)',
        },
      },
    },
    gridContainer: {
      textAlign: 'left',
      position: 'absolute',
      left: '10px',
      top: '10px',
      color: 'white',
      fontFamily: 'inherit',
      lineHeight: 1.75,
      fontWeight: 1000,
      fontSize: '2em',
    },
    gridHeader: {
      textAlign: 'left',
      position: 'absolute',
      bottom: '20px',
      left: '10px',
      fontSize: '1em',
      fontWeight: 500,
      color: 'white',
    },
  };
});

const InfoCard: React.FC<InfoCardProps> = ({ text, title, icon, subtitle, fullWidth, style, backgroundColor }) => {
  const classes = useStyle();
  return (
    <Card
      style={{ backgroundColor, ...style }}
      className={classnames(classes.InfoCard, { [classes.widthFull]: fullWidth, [classes.widthAuto]: !fullWidth })}
    >
      <Typography className={classes.gridContainer}>
        {text} {subtitle}
      </Typography>
      <Typography className={classes.gridHeader}>{title}</Typography>
      {icon && (
        <Box className="iconStyle" textAlign="right" position="absolute" right="0px" top="20px">
          {icon}
        </Box>
      )}
    </Card>
  );
};

export default InfoCard;
