/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  Divider,
  fade,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, CreditCard as CreditCardIcon, Send as SendIcon } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { LoadingButton, FormMaskedTextField, FormTextField } from 'reactmuicommon';
import {
  Api,
  ConfirmDialog,
  FileUploader,
  FormSelect,
  getCommissionRate,
  InstallmentType,
  isMainAgent,
  LabeledCheckbox,
  LinkModal,
  PaymentSourceEnum,
  showToast,
  thousandSeparatorWithTLSeperator,
} from 'core';
import * as yup from 'yup';
import { PaynetRatio, BankInfo, PaynetGetInstalmentRequest } from 'apps/paynet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { UserInfo } from 'apps/auth/models';
import NewFormTextField, { maskedCreditCard, maskedCvc, maskedValidDate } from 'apps/paynet/components/FormTextField';
import Back from 'apps/paynet/components/Back';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      paddingLeft: 5,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
    },
    summary: {
      backgroundColor: fade(theme.palette.grey[700], 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.grey[700], 0.25),
      },
    },
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 13,
      textAlign: 'center',
      padding: '2px 2px',
      '&:hover': {
        backgroundColor: '#FFE194 !important',
      },
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      fontSize: 12,
    },
    body: {
      fontSize: 12,
    },
  }),
)(TableRow);

const creditCardFormSchema = yup.object({
  cardHolder: yup.string().required('Boş olamaz!'),
  cardNumber: yup
    .string()
    .transform((v) => v.replaceAll('-', '').replaceAll('_', ''))
    .required('Boş olamaz!')
    .length(16, '16 haneli olmalıdır!'),
  cvc: yup
    .string()
    .transform((v) => v.replaceAll('_', ''))
    .required('Boş olamaz!'),
  validDate: yup
    .string()
    .transform((v) => v.replaceAll('_', '').replaceAll('/', ''))
    .required('Boş olamaz!')
    .length(4, 'AA/YY formatında olmalıdır! (02/25)'),
  amount: yup.string().required('Boş olamaz!'),
});

const paylinkFormSchema = yup.object({
  email: yup.string().email('Email formatı yanlış!').notRequired(),
  pAmount: yup.string().required('Boş olamaz!'),
  gsm: yup
    .string()
    .transform((v) => v.replaceAll('_', '').replaceAll('-', ''))
    .required('Boş olamaz!')
    .length(10, '10 haneli şekilde giriniz'),
  nameSurname: yup.string().required('Boş olamaz!'),
});

const PaynetPaymentPage = () => {
  const classes = useStyles();
  const initialValuesForCreditCardPayment = {
    amount: '',
    cardHolder: '',
    cardNumber: '',
    cvc: '',
    validDate: '',
    addCommission: true,
  };
  const initialValuesForPaylinkPayment = {
    pAmount: '',
    gsm: '',
    email: '',
    nameSurname: '',
    note: '',
    addCommission: true,
  };

  const [showLoading, setShowLoading] = React.useState(false);
  const [instalmentLoading, setInstalmentLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [postUrl, setPostUrl] = React.useState<string>('');
  const [htmlContent, setHtmlContent] = React.useState<string | undefined>(undefined);

  const [instalmentRatioListForCC, setInstalmentRatioListForCC] = React.useState<any[]>([]);
  const [instalmentRatioColumnsForCC, setInstalmentRatioColumnsForCC] = React.useState<any[]>([]);
  const [instalmentRatioListForBC, setInstalmentRatioListForBC] = React.useState<any[]>([]);
  const [instalmentRatioColumnsForBC, setInstalmentRatioColumnsForBC] = React.useState<any[]>([]);
  const [selectedPaynetRatio, setSelectedPaynetRatio] = React.useState<PaynetRatio | undefined>(undefined);
  const [selectedBankInfo, setSelectedBankInfo] = React.useState<BankInfo | null>(null);
  const [selectedBankInfoForCreditCard, setSelectedBankInfoForCreditCard] = React.useState<BankInfo | undefined>(
    undefined,
  );
  const [creditCardAmount, setCreditCardAmount] = React.useState<string>('');
  const [finalCreditCardAmount, setFinalCreditCardAmount] = React.useState<number>(0);
  const [tekbasCommissionForCreditCard, setTekbasCommissionForCreditCard] = React.useState<number>(0);
  const [paylinkAmount, setPaylinkAmount] = React.useState<string>('');
  const [selectedFilesForCreditCard, setSelectedFilesForCreditCard] = React.useState<any[]>([]);
  const [selectedFilesForPaylink, setSelectedFilesForPaylink] = React.useState<any[]>([]);
  const [helperTextForCreditCard, setHelperTextForCreditCard] = React.useState<string>('');
  const [selectedCardType, setSelectedCardType] = React.useState('cc');
  const [addCommissionForCreditCard, setAddCommissionForCreditCard] = React.useState<boolean>(true);
  const [addCommissionForPayLink, setAddCommissionForPayLink] = React.useState<boolean>(true);
  const [showFileUploader, setShowFileUploader] = React.useState<boolean>(true);
  const [formDataCreditCard, setFormDataCreditCard] = React.useState<any>();
  const [formikBagCreditCard, setFormikBagCreditCard] = React.useState<any>();
  const [formDataPayLink, setFormDataPayLink] = React.useState<any>();
  const [formikBagPayLink, setFormikBagPayLink] = React.useState<any>();
  const [showConfirmDialogCreditCard, setShowConfirmDialogCreditCard] = React.useState<boolean>(false);
  const [showConfirmDialogPayLink, setShowConfirmDialogPayLink] = React.useState<boolean>(false);
  const [selectedPaymentSourceType, setSelectedPaymentSourceType] = React.useState<PaymentSourceEnum>(
    PaymentSourceEnum.PAYNET,
  );
  const [creditCardNumber, setCreditCardNumber] = React.useState<string>('');

  const {
    paymentTransactionIdForIframe,
    paymentAmountForIframe,
    isPackagePayment,
    isMembershipPayment,
    userInfo,
    installmentType,
    paymentOrderType,
  } = useSelector(
    (state: any) => ({
      paymentTransactionIdForIframe: state.core.paymentTransactionId,
      paymentAmountForIframe: state.core.paymentAmount,
      isPackagePayment: state.core.isPackagePayment,
      isMembershipPayment: state.core.isMembershipPayment,
      userInfo: state.auth.userInfo as UserInfo,
      installmentType: state.core.installmentType,
      paymentOrderType: state.core.paymentOrderType,
    }),
    shallowEqual,
  );

  const hideNkolay = !(isMainAgent(userInfo.paynetAgentId) || userInfo.isAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentAmountForIframe) {
      initialValuesForCreditCardPayment.amount = paymentAmountForIframe.toString().replace('.', ',');
      calculateCreditCardAmount(Number(paymentAmountForIframe));
    }
  }, [paymentAmountForIframe]);

  useEffect(() => {
    getRatiosForCreditCardPayment();
  }, [selectedBankInfo, finalCreditCardAmount, addCommissionForCreditCard, creditCardNumber]);

  useEffect(() => {
    calculateAndSetFinalCreditCardAmount();
  }, [creditCardAmount]);

  useEffect(() => {
    let text = '';
    if (selectedPaynetRatio && finalCreditCardAmount) {
      const tekbasCommission =
        Number(selectedPaynetRatio.totalAmount) * getCommissionRate(userInfo.paynetAgentId, userInfo.isAdmin);
      const paynetCommission = selectedPaynetRatio.totalAmount - selectedPaynetRatio.totalNetAmount;
      text = `Hizmet Bedelleri; Aracılık: ${tekbasCommission.toFixed(2)} ₺ - Banka: ${paynetCommission.toFixed(2)} ₺`;
      setTekbasCommissionForCreditCard(tekbasCommission);
    } else {
      setTekbasCommissionForCreditCard(0);
    }
    setHelperTextForCreditCard(text);
  }, [selectedPaynetRatio, finalCreditCardAmount]);

  useEffect(() => {
    const requestDto = {
      addCommission: addCommissionForCreditCard,
      cardType: selectedCardType,
      // cardNumber: creditCardNumber,
      amount: Number(creditCardAmount),
    } as PaynetGetInstalmentRequest;
    // if (selectedPaymentSourceType == PaymentSourceEnum.NKOLAY && (!creditCardNumber || creditCardNumber == '')) return;

    if (
      (selectedCardType === 'cc' && instalmentRatioColumnsForCC.length === 0) ||
      (selectedCardType === 'bc' && instalmentRatioColumnsForBC.length === 0) ||
      selectedPaymentSourceType != PaymentSourceEnum.PAYNET
    ) {
      setInstalmentLoading(true);

      Api.Paynet.getInstalmentInfo(requestDto, selectedPaymentSourceType)
        .then((response) => {
          if (response.isSuccess && response.result && response.result?.data) {
            setInstalmentInfoTable(response.result.data);
          } else {
            dispatch(
              showToast({
                message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
                severity: 'error',
              }),
            );
          }
        })
        .catch((err) => {
          dispatch(
            showToast({
              message: `Bir hata oluştu. ${err ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
              severity: 'error',
            }),
          );
        })
        .finally(() => {
          setInstalmentLoading(false);
        });
    }
  }, [
    selectedCardType,
    addCommissionForCreditCard,
    // creditCardNumber,
    selectedPaymentSourceType,
    finalCreditCardAmount,
  ]);

  useEffect(() => {
    if (isPackagePayment || isMembershipPayment) {
      setAddCommissionForCreditCard(false);
      setAddCommissionForPayLink(false);
      setShowFileUploader(false);
    }
    if (selectedPaymentSourceType == PaymentSourceEnum.NKOLAY) {
      setAddCommissionForCreditCard(true);
    }
  }, [isPackagePayment, isMembershipPayment, selectedPaymentSourceType]);

  useEffect(() => {
    setInstalmentRatioColumnsForCC([]);
    setInstalmentRatioListForCC([]);
    setInstalmentRatioColumnsForBC([]);
    setInstalmentRatioListForBC([]);
    setSelectedBankInfoForCreditCard(undefined);
    setSelectedPaynetRatio(undefined);
    setSelectedBankInfo(null);
  }, [selectedPaymentSourceType]);

  const getInitialPaymentRequestFormData = (data: any): FormData => {
    const result = new FormData();
    selectedFilesForCreditCard.forEach((file) => {
      result.append('files', file);
    });

    result.append('addCommission', addCommissionForCreditCard.toString());
    // TODO: burası iyileştirilebilir
    if (selectedPaymentSourceType == PaymentSourceEnum.PAYNET)
      result.append('amount', finalCreditCardAmount.toString());
    else if (selectedPaymentSourceType == PaymentSourceEnum.NKOLAY)
      result.append('amount', selectedPaynetRatio?.totalAmount.toString() ?? '');

    result.append('cardHolder', data.cardHolder);
    result.append('cardNumber', data.cardNumber.replaceAll('-', '').replaceAll('_', ''));
    result.append('domain', 'OccoPay');
    result.append('cvc', data.cvc);
    result.append('month', data.validDate.slice(0, 2).toString());
    result.append('year', data.validDate.slice(2, 4).toString());
    result.append('instalmentKey', selectedPaynetRatio?.instalmentKey.toString() ?? '');

    if (selectedPaynetRatio?.instalment) {
      result.append('instalment', selectedPaynetRatio.instalment.toString());
    }

    if (paymentTransactionIdForIframe) {
      result.append('paymentTransactionId', paymentTransactionIdForIframe.toString());
    }

    if (paymentOrderType) {
      result.append('orderType', paymentOrderType.toString());
    }

    return result;
  };

  const getCreatePaylinkRequestDto = (data: any): FormData => {
    const result = new FormData();
    selectedFilesForPaylink.forEach((file) => {
      result.append('files', file);
    });

    result.append('addCommission', addCommissionForPayLink.toString());
    result.append('amount', paylinkAmount);
    if (data.email && data.email != '') {
      result.append('email', data.email);
    }
    result.append('phone', data.gsm.replaceAll('-', '').replaceAll('_', ''));
    result.append('nameSurname', data.nameSurname);
    result.append('sendEmail', data.email != null && data.email != '' ? 'true' : 'false');
    result.append('sendSms', 'true');
    result.append('multiPayment', 'false');
    result.append('note', data.note);

    return result;
  };

  const onCreditCardPaymentBeforeSubmit = async (formData: any, formikBag: any) => {
    if (!isPackagePayment && !isMembershipPayment) {
      setFormDataCreditCard(formData);
      setFormikBagCreditCard(formikBag);
      setShowConfirmDialogCreditCard(true);
    } else {
      onCreditCardPaymentSubmit(formData, formikBag);
    }
  };

  const onCreditCardPaymentSubmit = async (formData: any, formikBag: any) => {
    if (selectedPaynetRatio == null) {
      dispatch(
        showToast({
          message: `Lütfen oran tablosundan taksit oranını seçiniz.`,
          severity: 'warning',
        }),
      );
      return;
    }
    setShowLoading(true);

    try {
      const response = await Api.Paynet.tdsInitialPayment(
        getInitialPaymentRequestFormData(formData),
        selectedPaymentSourceType,
      );
      if (response.isSuccess && response.result) {
        setPostUrl(response.result.postUrl);
        setHtmlContent(response.result.htmlContent);
        setOpenModal(true);
        formikBag.resetForm({});
        setInstalmentRatioColumnsForCC([]);
        setInstalmentRatioListForCC([]);
        setInstalmentRatioColumnsForBC([]);
        setInstalmentRatioListForBC([]);
        setSelectedBankInfoForCreditCard(undefined);
        setSelectedPaynetRatio(undefined);
        setSelectedBankInfo(null);
        setCreditCardNumber('');
      } else {
        dispatch(
          showToast({
            message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    } catch (e) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${e.message ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    }

    setShowLoading(false);
  };

  const handleOnConfirmDialogCreditCard = () => {
    onCreditCardPaymentSubmit(formDataCreditCard, formikBagCreditCard);
    setFormDataCreditCard(null);
    setFormikBagCreditCard(null);
    setShowConfirmDialogCreditCard(false);
  };

  const handleOnCloseDialogCreditCard = () => {
    setShowConfirmDialogCreditCard(false);
    setFormDataCreditCard(null);
    setFormikBagCreditCard(null);
  };

  const onPaylinkPaymentBeforeSubmit = async (formData: any, formikBag: any) => {
    if (!isPackagePayment && !isMembershipPayment) {
      setFormDataPayLink(formData);
      setFormikBagPayLink(formikBag);
      setShowConfirmDialogPayLink(true);
    } else {
      onPaylinkPaymentSubmit(formData, formikBag);
    }
  };

  const onPaylinkPaymentSubmit = async (formData: any, formikBag: any) => {
    setShowLoading(true);

    try {
      const response = await Api.Paynet.createPaylink(getCreatePaylinkRequestDto(formData));
      if (response.isSuccess) {
        formikBag.resetForm({});
        dispatch(
          showToast({
            message: `OccoLink başarıyla gönderildi!`,
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    } catch (e) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${e.message ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    }
    setShowLoading(false);
  };

  const handleOnConfirmDialogPayLink = () => {
    onPaylinkPaymentSubmit(formDataPayLink, formikBagPayLink);
    setFormDataPayLink(null);
    setFormikBagPayLink(null);
    setShowConfirmDialogPayLink(false);
  };

  const handleOnCloseDialogPayLink = () => {
    setShowConfirmDialogPayLink(false);
    setFormDataPayLink(null);
    setFormikBagPayLink(null);
  };

  const getRatiosForCreditCardPayment = async () => {
    if (!finalCreditCardAmount || !selectedBankInfo) {
      setSelectedBankInfoForCreditCard(undefined);
      return;
    }
    if (selectedPaymentSourceType == PaymentSourceEnum.NKOLAY && (!creditCardNumber || creditCardNumber == '')) {
      dispatch(
        showToast({
          message: `Lütfen kart numarasını giriniz.`,
          severity: 'error',
        }),
      );
      return;
    }

    const requestDto = {
      addCommission: addCommissionForCreditCard,
      amount: finalCreditCardAmount,
      cardType: selectedCardType,
      cardNumber: creditCardNumber,
    } as PaynetGetInstalmentRequest;
    const response = await Api.Paynet.getInstalmentInfo(requestDto, selectedPaymentSourceType);

    if (response.isSuccess && response.result) {
      // setInstalmentInfoTable(response.result.data);
      const bankInfo =
        response.result.data.length > 1
          ? response.result.data.find((bank: BankInfo) => bank.bankId == selectedBankInfo?.bankId)
          : response.result.data[0];
      const paynetRatio = bankInfo?.ratios.find(
        (pRatio: PaynetRatio) => pRatio.instalment == selectedPaynetRatio?.instalment,
      );

      setSelectedPaynetRatio(paynetRatio);
      setSelectedBankInfoForCreditCard(bankInfo);
    } else {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    }
  };

  const handleOnCloseModal = () => {
    setOpenModal(false);
    setPostUrl('');
    setHtmlContent(undefined);
  };

  const onClickPaynetRatio = async (bankInfo: BankInfo, paynetRatio: PaynetRatio) => {
    if (isMembershipPayment && installmentType >= 1) {
      if (installmentType == InstallmentType.PAY_WITH_NO_INSTALLMENT && paynetRatio.instalment != 0) {
        dispatch(
          showToast({
            message: `Ödeme seçeneğini "Tek Çekim" olarak seçtiğiniz için, "Taksitli" oran seçemezsiniz. 
                      Lütfen "Tek Çekim" oranlarından bir tanesini seçiniz veya ödeme seçeneğini değiştiriniz.`,
            severity: 'warning',
          }),
        );
        return;
      }
      if (installmentType == InstallmentType.PAY_WITH_INSTALLMENT && paynetRatio.instalment == 0) {
        dispatch(
          showToast({
            message: `Ödeme seçeneğini "Taksitli Çekim" olarak seçtiğiniz için, "Tek Çekim" oranı seçemezsiniz. 
                      Lütfen "Taksitli Çekim" oranlarından bir tanesini seçiniz veya ödeme seçeneğini değiştiriniz.`,
            severity: 'warning',
          }),
        );
        return;
      }
    }

    if (isPackagePayment) {
      // paket veya ihaleden provizyonda gelir.
      if (paynetRatio.instalment != 0) {
        dispatch(
          showToast({
            message: `Paket veya provizyon alımlarında "Taksitli" ödeme yapamazsınız. Lütfen "Tek Çekim" seçeneklerini seçiniz.`,
            severity: 'warning',
          }),
        );
        return;
      }
    }

    setSelectedPaynetRatio(paynetRatio);
    setSelectedBankInfo(bankInfo);
  };

  const getInstalmentInfoTable = async (request: PaynetGetInstalmentRequest) => {
    if (
      selectedPaymentSourceType == PaymentSourceEnum.NKOLAY &&
      (!creditCardNumber || creditCardNumber == '' || !finalCreditCardAmount)
    ) {
      return null;
    }

    const response = await Api.Paynet.getInstalmentInfo(request, selectedPaymentSourceType);
    if (response.isSuccess && response.result && response.result?.data) {
      const columns: any[] = [];
      const ratioList: any[] = [];
      const data = response.result.data
        .filter((v: BankInfo) => v.cardType == request.cardType?.toString() || !v.cardType)
        .sort((a: BankInfo, b: BankInfo) => b.ratios.length - a.ratios.length); // Bireysel Kartlar

      columns.push(<StyledTableCell key="instalment" />);

      const maxInstalmentRatio = data[0].ratios.reduce((prev, current) => {
        return prev && prev.instalment > current.instalment ? prev : current;
      });

      for (let i = 1; i <= maxInstalmentRatio.instalment; i++) {
        const ratioDesc = i === 1 ? 'Tek Çekim' : `${i} Taksit`;
        columns.push(<StyledTableCell key={i}>{ratioDesc}</StyledTableCell>);
      }

      // data[0].ratios.map((ratio: PaynetRatio) => {
      //   columns.push(<StyledTableCell key={ratio.instalment}>{ratio.description}</StyledTableCell>);
      // });

      data.map((bank: BankInfo) => {
        const cells = [];
        cells.push(
          <StyledTableCell key={bank.bankLogo}>
            <img src={bank.bankLogo} alt={bank.bankName} title={bank.bankName} />
          </StyledTableCell>,
        );

        let installmentCellIndex = 1;
        bank.ratios.map((ratio: PaynetRatio) => {
          const instalmentTemp = ratio.instalment == 0 ? 1 : ratio.instalment;

          // taksit sayısı 1-2-3-4 gibi her zaman sıralı gitmiyor.
          if (instalmentTemp > installmentCellIndex) {
            for (let i = installmentCellIndex; i < instalmentTemp; i += 1) {
              cells.push(<StyledTableCell key={ratio.instalmentKey + i} />);
            }
            installmentCellIndex = instalmentTemp;
          }

          cells.push(
            <StyledTableCell key={ratio.instalmentKey} style={{ border: '1px solid rgba(97, 97, 97, 0.15)' }}>
              <div
                style={{
                  cursor: 'pointer',
                  minWidth: 40,
                  fontSize: 15,
                  backgroundColor:
                    selectedPaynetRatio?.instalmentKey == ratio.instalmentKey ? '#FFE194 !important' : '',
                }}
                onClick={() => onClickPaynetRatio(bank, ratio)}
              >
                <span>
                  <span>{`%${(ratio.ratio * 100).toFixed(2)}`}</span>
                  {ratio.plusInstallment !== 0 ? (
                    <Tooltip title={<h5>{ratio.campaignNote}</h5>} placement="top-start">
                      <span style={{ fontSize: 12, color: 'red', fontWeight: 'bold', marginLeft: 3, verticalAlign: 7 }}>
                        {`+${ratio.plusInstallment}`}
                      </span>
                    </Tooltip>
                  ) : (
                    <span />
                  )}
                </span>
              </div>
            </StyledTableCell>,
          );
          installmentCellIndex += 1;
        });

        const row = <StyledTableRow key={bank.bankId}>{cells}</StyledTableRow>;
        ratioList.push(row);
      });
      return { columns, ratioList };
    }

    dispatch(
      showToast({
        message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
        severity: 'error',
      }),
    );
    return null;
  };

  const setInstalmentInfoTable = (bankInfos: BankInfo[]): void => {
    // if (
    //   selectedPaymentSourceType == PaymentSourceEnum.NKOLAY &&
    //   (!creditCardNumber || creditCardNumber == '' || !finalCreditCardAmount)
    // ) {
    //   return;
    // }

    const columns: any[] = [];
    const ratioList: any[] = [];
    const data = bankInfos
      .filter((v: BankInfo) => v.cardType == selectedCardType?.toString() || !v.cardType)
      .sort((a: BankInfo, b: BankInfo) => b.ratios.length - a.ratios.length); // Bireysel Kartlar

    columns.push(<StyledTableCell key="instalment" />);

    const maxInstalmentRatio = data[0].ratios.reduce((prev, current) => {
      return prev && prev.instalment > current.instalment ? prev : current;
    });

    for (let i = 1; i <= maxInstalmentRatio.instalment; i++) {
      const ratioDesc = i === 1 ? 'Tek Çekim' : `${i} Taksit`;
      columns.push(<StyledTableCell key={i}>{ratioDesc}</StyledTableCell>);
    }

    // data[0].ratios.map((ratio: PaynetRatio) => {
    //   columns.push(<StyledTableCell key={ratio.instalment}>{ratio.description}</StyledTableCell>);
    // });

    data.map((bank: BankInfo) => {
      const cells = [];
      if (bank.bankLogo) {
        cells.push(
          <StyledTableCell key={Math.random() + bank.bankName}>
            <img src={bank.bankLogo} alt={bank.bankName} title={bank.bankName} />
          </StyledTableCell>,
        );
      } else {
        cells.push(<StyledTableCell key={Math.random() + bank.bankName}>{bank.bankName}</StyledTableCell>);
      }

      let installmentCellIndex = 1;
      bank.ratios.map((ratio: PaynetRatio) => {
        const instalmentTemp = ratio.instalment == 0 ? 1 : ratio.instalment;

        // taksit sayısı 1-2-3-4 gibi her zaman sıralı gitmiyor.
        if (instalmentTemp > installmentCellIndex) {
          for (let i = installmentCellIndex; i < instalmentTemp; i += 1) {
            cells.push(<StyledTableCell key={ratio.instalmentKey ?? Math.random() + i} />);
          }
          installmentCellIndex = instalmentTemp;
        }

        cells.push(
          <StyledTableCell
            key={Math.random() + ratio.instalmentKey}
            style={{ border: '1px solid rgba(97, 97, 97, 0.15)' }}
          >
            <div
              style={{
                cursor: 'pointer',
                minWidth: 40,
                fontSize: 15,
                backgroundColor: selectedPaynetRatio?.instalment == ratio.instalment ? '#FFE194 !important' : '',
              }}
              onClick={() => onClickPaynetRatio(bank, ratio)}
            >
              <span>
                <span>{`%${(ratio.ratio * 100).toFixed(2)}`}</span>
                {ratio.plusInstallment && ratio.plusInstallment !== 0 ? (
                  <Tooltip title={<h5>{ratio.campaignNote}</h5>} placement="top-start">
                    <span style={{ fontSize: 12, color: 'red', fontWeight: 'bold', marginLeft: 3, verticalAlign: 7 }}>
                      {`+${ratio.plusInstallment}`}
                    </span>
                  </Tooltip>
                ) : (
                  <span />
                )}
              </span>
            </div>
          </StyledTableCell>,
        );
        installmentCellIndex += 1;
      });

      const row = <StyledTableRow key={bank.bankId}>{cells}</StyledTableRow>;
      ratioList.push(row);
    });

    if (selectedPaymentSourceType == PaymentSourceEnum.NKOLAY) {
      setInstalmentRatioColumnsForCC(columns);
      setInstalmentRatioListForCC(ratioList);
      // if (refreshRatios) getRatiosForCreditCardPayment();
    } else if (selectedCardType == 'cc') {
      setInstalmentRatioColumnsForCC(columns);
      setInstalmentRatioListForCC(ratioList);
    } else if (selectedCardType == 'bc') {
      setInstalmentRatioColumnsForBC(columns);
      setInstalmentRatioListForBC(ratioList);
    }
  };

  const onBlurCreditCardAmount = async (event: any) => {
    const strAmount: string = event.target.value;
    const amount = strAmount ? strAmount.replace('₺', '').replaceAll(',', '').toString() : '';
    if (amount && amount != '') {
      calculateCreditCardAmount(Number(amount));
    } else {
      setCreditCardAmount('');
    }
  };

  const onBlurCreditCardNumber = async (event: any) => {
    setCreditCardNumber(event.target.value?.replaceAll('-', '').replaceAll('_', ''));
  };

  const calculateCreditCardAmount = (amount: number) => {
    setCreditCardAmount(amount.toString());
  };

  const calculateAndSetFinalCreditCardAmount = () => {
    setFinalCreditCardAmount(
      Number(creditCardAmount) * (1 + getCommissionRate(userInfo.paynetAgentId, userInfo.isAdmin)),
    );
  };

  const onBlurPaylinkAmount = async (event: any) => {
    const strAmount: string = event.target.value;
    const amount = strAmount ? strAmount.replace('₺', '').replaceAll(',', '').toString() : '';
    setPaylinkAmount(amount.toString());
  };

  const onDroppedFilesCreditCard = async (files: any) => {
    setSelectedFilesForCreditCard(files);
  };

  const onDroppedFilesPaylink = async (files: any) => {
    setSelectedFilesForPaylink(files);
  };

  const creditCardPaymentFragment = (
    <React.Fragment>
      <Box style={{ width: '100%' }}>
        <Formik
          onSubmit={onCreditCardPaymentBeforeSubmit}
          validationSchema={creditCardFormSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValuesForCreditCardPayment}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <FormTextField
                            id="amount"
                            type="text"
                            label="Tutar"
                            name="amount"
                            value={values.amount}
                            onBlur={onBlurCreditCardAmount}
                            onChange={(e) => setFieldValue('amount', parseInt(e.target.value, 10) || undefined)}
                            placeholder="Tutar"
                            disabled={paymentAmountForIframe != null}
                            required
                            InputProps={{
                              inputComponent: thousandSeparatorWithTLSeperator,
                            }}
                          />
                          <FormHelperText error>{errors.amount}</FormHelperText>
                          <FormHelperText>{helperTextForCreditCard}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        {!isPackagePayment && !isMembershipPayment && (
                          <LabeledCheckbox
                            checked={addCommissionForCreditCard}
                            label="Hizmet Bedelini Ekle"
                            onChange={(e) => setAddCommissionForCreditCard(e.target.checked)}
                            name="addCommission"
                            disabled={
                              isPackagePayment ||
                              isMembershipPayment ||
                              selectedPaymentSourceType == PaymentSourceEnum.NKOLAY
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <InputLabel>Kart Üzerindeki İsim</InputLabel>
                          <OutlinedInput
                            id="cardHolder"
                            type="text"
                            margin="dense"
                            label="Kart Üzerindeki İsim"
                            name="cardHolder"
                            value={values.cardHolder}
                            required
                            onChange={handleChange}
                            placeholder="Kart Üzerindeki İsim"
                          />
                          <FormHelperText error>{errors.cardHolder}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                          <NewFormTextField
                            required
                            label="Kart Numarası"
                            placeholder="____-____-____-____"
                            name="cardNumber"
                            onBlur={onBlurCreditCardNumber}
                            onChange={handleChange}
                            value={values.cardNumber}
                            error={errors.cardNumber != null}
                            helperText={errors.cardNumber}
                            InputProps={{
                              inputComponent: maskedCreditCard,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                          <NewFormTextField
                            required
                            label="Cvc"
                            placeholder="___"
                            name="cvc"
                            onChange={handleChange}
                            value={values.cvc}
                            error={errors.cvc != null}
                            helperText={errors.cvc}
                            InputProps={{
                              inputComponent: maskedCvc,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                          <NewFormTextField
                            required
                            label="Son Kullanma Tarihi"
                            placeholder="__/__"
                            name="validDate"
                            onChange={handleChange}
                            value={values.validDate}
                            error={errors.validDate != null}
                            helperText={errors.validDate}
                            InputProps={{
                              inputComponent: maskedValidDate,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormSelect
                          hidden={hideNkolay}
                          value={selectedPaymentSourceType.toString()}
                          fullWidth
                          name="paymentSourceType"
                          onChange={(e) => setSelectedPaymentSourceType(e.target.value)}
                          label="Ödeme Sağlayıcısı"
                          placeholder="Ödeme Sağlayıcısı"
                        >
                          <MenuItem value="0">Paynet</MenuItem>
                          <MenuItem value="10">NKolay</MenuItem>
                        </FormSelect>
                      </Grid>
                    </Grid>

                    <br />
                    {/*    {showFileUploader && (
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <FileUploader onChange={onDroppedFilesCreditCard} />
                            </Grid>
                          </Grid>
                        )} */}
                    {instalmentRatioFragment}
                    <br />
                    <Grid item xs={12} sm={12} style={{ marginBottom: 30 }}>
                      <LoadingButton
                        color="primary"
                        type="submit"
                        variant="contained"
                        disableElevation
                        showLoading={showLoading}
                      >
                        Ödeme Al
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  {selectedBankInfoForCreditCard && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={2}>
                        <TableContainer component={Paper}>
                          <Table stickyHeader size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  <img
                                    src={selectedBankInfoForCreditCard?.bankLogo}
                                    alt={selectedBankInfoForCreditCard?.bankName}
                                    title={selectedBankInfoForCreditCard?.bankName}
                                  />
                                </StyledTableCell>
                                <StyledTableCell>Taksit</StyledTableCell>
                                <StyledTableCell>Oran</StyledTableCell>
                                <StyledTableCell>Çekilecek Tutar</StyledTableCell>
                                <StyledTableCell>Net Tutar</StyledTableCell>
                                <StyledTableCell>Hizmet Bedeli</StyledTableCell>
                                <StyledTableCell>Aylık Ödeme</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedBankInfoForCreditCard?.ratios.map((pRatio: PaynetRatio) => (
                                <StyledTableRow key={Math.random() + pRatio.instalmentKey}>
                                  <TableCell>
                                    <Radio
                                      checked={selectedPaynetRatio?.instalment == pRatio.instalment}
                                      value={pRatio}
                                      onChange={() => setSelectedPaynetRatio(pRatio)}
                                      name="radio-paynet-ratio"
                                      size="small"
                                      disabled={
                                        (isPackagePayment && pRatio.instalment != 0) ||
                                        (pRatio.instalment == 0 &&
                                          isMembershipPayment &&
                                          installmentType == InstallmentType.PAY_WITH_INSTALLMENT) ||
                                        (pRatio.instalment != 0 &&
                                          isMembershipPayment &&
                                          installmentType == InstallmentType.PAY_WITH_NO_INSTALLMENT)
                                      }
                                    />
                                  </TableCell>
                                  <StyledTableCell>{pRatio.description}</StyledTableCell>
                                  <StyledTableCell>{(pRatio.ratio * 100).toFixed(2)}</StyledTableCell>
                                  <StyledTableCell>{pRatio.totalAmount.toFixed(2)} ₺</StyledTableCell>
                                  <StyledTableCell>
                                    {(pRatio.totalNetAmount - tekbasCommissionForCreditCard).toFixed(2)} ₺
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {(
                                      pRatio.totalAmount +
                                      tekbasCommissionForCreditCard -
                                      pRatio.totalNetAmount
                                    ).toFixed(2)}{' '}
                                    ₺
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {(pRatio.totalAmount / (pRatio.instalment == 0 ? 1 : pRatio.instalment)).toFixed(2)}{' '}
                                    ₺
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );

  const paylinkPaymentFragment = (
    <React.Fragment>
      <Box style={{ width: '100%' }}>
        <Card>
          <Formik
            onSubmit={onPaylinkPaymentBeforeSubmit}
            validationSchema={paylinkFormSchema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValuesForPaylinkPayment}
          >
            {({ handleSubmit, handleChange, setFieldValue, errors, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <CardContent>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <FormTextField
                            id="pAmount"
                            type="text"
                            margin="dense"
                            label="Tutar"
                            value={values.pAmount}
                            name="pAmount"
                            onBlur={onBlurPaylinkAmount}
                            onChange={(e) => setFieldValue('pAmount', parseInt(e.target.value, 10) || undefined)}
                            placeholder="Tutar"
                            required
                            InputProps={{
                              inputComponent: thousandSeparatorWithTLSeperator,
                            }}
                          />
                          <FormHelperText error>{errors.pAmount}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <LabeledCheckbox
                          checked={addCommissionForPayLink}
                          label="Hizmet Bedelini Ekle"
                          onChange={(e) => setAddCommissionForPayLink(e.target.checked)}
                          name="addCommission"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8} md={3}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <InputLabel>Email Adresi</InputLabel>
                          <OutlinedInput
                            id="email"
                            type="email"
                            margin="dense"
                            label="Email Adresi"
                            name="email"
                            required
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email Adresi"
                          />
                          <FormHelperText error>{errors.email}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl variant="outlined" fullWidth>
                          <FormMaskedTextField
                            placeholder="Telefon numarası"
                            name="gsm"
                            value={values.gsm}
                            required
                            onChange={handleChange}
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '-',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '-',
                              /[0-9]/,
                              /[0-9]/,
                              '-',
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                          />
                          <FormHelperText error>{errors.gsm}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8} md={3}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <InputLabel>Ad Soyad</InputLabel>
                          <OutlinedInput
                            id="nameSurname"
                            type="text"
                            margin="dense"
                            label="Ödeme Yapacak Kişinin Adı Soyadı"
                            name="nameSurname"
                            value={values.nameSurname}
                            required
                            onChange={handleChange}
                            placeholder="Ödeme Yapacak Kişinin Adı Soyadı"
                          />
                          <FormHelperText error>{errors.nameSurname}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={5}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                          <InputLabel>Müşteri Açıklaması</InputLabel>
                          <OutlinedInput
                            id="note"
                            type="text"
                            margin="dense"
                            label="Müşterinin göreceği açıklama"
                            value={values.note}
                            name="note"
                            onChange={handleChange}
                            placeholder="Müşterinin göreceği açıklama"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {showFileUploader && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <FileUploader onChange={onDroppedFilesPaylink} />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <LoadingButton
                          color="primary"
                          type="submit"
                          variant="contained"
                          disableElevation
                          showLoading={showLoading}
                        >
                          OccoLink Gönder
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </form>
            )}
          </Formik>
        </Card>
      </Box>
    </React.Fragment>
  );

  const instalmentRatioFragment = (
    <React.Fragment>
      <Box style={{ width: '100%' }}>
        {selectedPaymentSourceType == PaymentSourceEnum.PAYNET && (
          <TabContext value={selectedCardType}>
            <TabList onChange={(e, val) => setSelectedCardType(val)} aria-label="Oran Tablosu">
              <Tab label="Bireysel Kartlar" value="cc" />
              <Tab label="Ticari Kartlar" value="bc" />
            </TabList>
            <TabPanel value="cc">
              {instalmentLoading && <CircularProgress style={{ alignContent: 'center', display: 'flex' }} />}
              <TableContainer component={Paper}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>{instalmentRatioColumnsForCC.length != 0 && instalmentRatioColumnsForCC}</TableRow>
                  </TableHead>
                  <TableBody>
                    {!instalmentLoading && instalmentRatioListForCC.length != 0 && instalmentRatioListForCC}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value="bc">
              {instalmentLoading && <CircularProgress style={{ alignContent: 'center', display: 'flex' }} />}
              <TableContainer component={Paper}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>{instalmentRatioColumnsForBC.length != 0 && instalmentRatioColumnsForBC}</TableRow>
                  </TableHead>
                  <TableBody>
                    {!instalmentLoading && instalmentRatioListForBC.length != 0 && instalmentRatioListForBC}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        )}
        {/* {selectedPaymentSourceType == PaymentSourceEnum.NKOLAY &&
          !instalmentLoading &&
          instalmentRatioListForCC.length == 0 && (
            <p>Oranları görmek için lütfen önce kredi kartı numarası ve tutar bilgilerini giriniz.</p>
          )} */}
        {selectedPaymentSourceType == PaymentSourceEnum.NKOLAY && (
          <React.Fragment>
            {instalmentLoading && <CircularProgress style={{ alignContent: 'center', display: 'flex' }} />}
            <TableContainer component={Paper}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>{instalmentRatioColumnsForCC.length != 0 && instalmentRatioColumnsForCC}</TableRow>
                </TableHead>
                <TableBody>
                  {!instalmentLoading && instalmentRatioListForCC.length != 0 && instalmentRatioListForCC}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );

  const warningText = (
    <p>
      Yaptığınız tahsilat işleminde kullanılan kredı kartı araç sahibine ait değil ise tahsilat işleminden sonra ilgili
      tahsilat için "Muvafakatname" sözleşmesi onaylatmanız gerekmektedir. Muvafakatname sözleşmesini onaylatmak için
      "İşlemler / Ödemelerim" sayfasına giderek, ilgili işlem için Kredi Kartı sahibine sözleşmeyi gönderebilir ve onay
      işlemini gerçekleştirebilirsiniz.
      <br />
      Aksi taktirde kredı kartından yapılan tahsilat tutarı ödenmeyecektir.
    </p>
  );

  return (
    <React.Fragment>
      <br />
      <br />
      <Back />
      <Typography variant="h4">Kredi Kartı İle Ödeme</Typography>
      <br />
      <Divider />
      <br />
      {creditCardPaymentFragment}
      <Divider />
      {!paymentTransactionIdForIframe && (
        <Accordion defaultExpanded={false} hidden>
          <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SendIcon />
            <Typography className={classes.heading}>OccoLink İle Ödeme</Typography>
          </AccordionSummary>
          <AccordionDetails>{paylinkPaymentFragment}</AccordionDetails>
        </Accordion>
      )}
      {openModal && (
        <div>
          <LinkModal isOpen={openModal} onClose={handleOnCloseModal} href={postUrl} htmlContent={htmlContent} />
        </div>
      )}
      <ConfirmDialog
        title="Uyarı !"
        open={showConfirmDialogCreditCard}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={handleOnConfirmDialogCreditCard}
        handleClose={handleOnCloseDialogCreditCard}
      >
        {warningText}
      </ConfirmDialog>
      <ConfirmDialog
        title="Uyarı !"
        open={showConfirmDialogPayLink}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={handleOnConfirmDialogPayLink}
        handleClose={handleOnCloseDialogPayLink}
      >
        {warningText}
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default PaynetPaymentPage;
