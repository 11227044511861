import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import moment from 'moment';
import 'moment/locale/tr';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { trTR } from '@material-ui/core/locale';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { createStore } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';

const store = createStore();
moment.locale('tr');

const theme = createMuiTheme(
  {
    palette: {
      type: 'light',
      primary: {
        main: '#6c9c28',
      },
      secondary: {
        main: '#202020',
      },
    },
  },
  trTR,
);

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store.store}>
        <ThemeProvider theme={theme}>
          {/* <SimpleReactLightbox> */}
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale="tr">
            <App />
          </MuiPickersUtilsProvider>
          {/* </SimpleReactLightbox> */}
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
