import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import { LoadingButton } from 'reactmuicommon';
import { Api, FileUploader, showToast } from 'core';
import { useDispatch } from 'react-redux';
import Back from 'apps/paynet/components/Back';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router';

const formSchema = yup.object({
  email: yup.string().email().required('Boş olamaz'),
  paynetAgentId: yup.string().required('Boş olamaz'),
  agentName: yup.string().required('Boş olamaz'),
});

const RegisterUserPage = () => {
  const initialValues = { email: '', paynetAgentId: '', agentName: '' };
  const history = useHistory();
  const [showLoading, setShowLoading] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = React.useState<any>();
  const dispatch = useDispatch();

  const onSubmit = async (formData: any, { resetForm }: any) => {
    const request = new FormData();
    request.append('logo', selectedLogo);
    request.append('email', formData.email);
    request.append('paynetAgentId', formData.paynetAgentId);
    request.append('agentName', formData.agentName);

    setShowLoading(true);

    try {
      const response = await Api.Auth.register(request);
      if (response.isSuccess && response.result) {
        resetForm({});
        dispatch(
          showToast({
            message: `Kullanıcı başarıyla kaydedildi!`,
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    } catch (e) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${e.message ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    }

    setShowLoading(false);
  };

  const onDroppedFilesCreditCard = async (files: any) => {
    setSelectedLogo(files[0]);
  };

  return (
    <React.Fragment>
      <br />
      <br />
      <IconButton onClick={() => history.goBack()}>
        <ArrowBack />
      </IconButton>
      <Box style={{ width: '100%' }}>
        <Card>
          <CardHeader title="Yeni Kullanıcı Ekle" />
          <Divider />
          <Formik
            onSubmit={onSubmit}
            validationSchema={formSchema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
          >
            {({ handleSubmit, handleChange, errors, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <CardContent>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl variant="outlined" fullWidth margin="dense">
                              <InputLabel>Email Adresi</InputLabel>
                              <OutlinedInput
                                id="email"
                                type="email"
                                label="Email Adresi"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                placeholder="Email Adresi"
                                required
                              />
                              <FormHelperText error>{errors.email}</FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl variant="outlined" fullWidth margin="dense">
                              <InputLabel>Bayi Adı</InputLabel>
                              <OutlinedInput
                                id="agentName"
                                type="text"
                                label="Bayi Adı"
                                name="agentName"
                                value={values.agentName}
                                onChange={handleChange}
                                placeholder="Bayi Adı"
                                required
                              />
                              <FormHelperText error>{errors.agentName}</FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl variant="outlined" fullWidth margin="dense">
                              <InputLabel>Paynet Bayi Id</InputLabel>
                              <OutlinedInput
                                id="paynetAgentId"
                                type="text"
                                label="Paynet Bayi Id"
                                name="paynetAgentId"
                                value={values.paynetAgentId}
                                onChange={handleChange}
                                placeholder="Paynet Bayi Id"
                                required
                              />
                              <FormHelperText error>{errors.paynetAgentId}</FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <FileUploader
                          maxFilesCount={1}
                          text="Bayinin logosunu buraya yükleyebilirsiniz"
                          onChange={onDroppedFilesCreditCard}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <LoadingButton
                          color="primary"
                          type="submit"
                          variant="contained"
                          disableElevation
                          showLoading={showLoading}
                        >
                          Kaydet
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </form>
            )}
          </Formik>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default RegisterUserPage;
