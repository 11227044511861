import { GenericResponse, paymentClient, PaymentSourceEnum } from 'core';
import {
  PaynetCaptureTransactionRequest,
  PaynetCaptureTransactionResponse,
  PaynetCreatePayLinkResponse,
  PaynetGetInstalmentRequest,
  PaynetGetInstalmentResponse,
  PaynetInitialPaymentResponse,
  PaynetReverseTransactionRequest,
  PaynetReverseTransactionResponse,
} from '../models';

export default {
  PAYNET_TDS_INITIAL_PAYMENT: '/paynet/tdsInitialPayment',
  PAYNET_CREATE_PAYLINK: '/paynet/payLinkPayment',
  PAYNET_GET_INSTALMENT_INFO: '/paynet/getInstalmentInfo',
  PAYNET_CAPTURE_TRANSACTION: '/paynet/captureTransaction',
  PAYNET_REVERSE_TRANSACTION: '/paynet/createReverseTransactionRequest',
  PAYNET_ADMIN_APPROVE_REVERSE_TRANSACTION: (paymentTransactionId: number, paymentTransactionRefundId: string) =>
    `/paynet/adminApproveReverseTransactionRequest/${paymentTransactionId}/${paymentTransactionRefundId}`,

  NKOLAY_TDS_INITIAL_PAYMENT: '/nkolay/tdsInitialPayment',
  NKOLAY_CREATE_PAYLINK: '/nkolay/payLinkPayment',
  NKOLAY_GET_INSTALMENT_INFO: '/nkolay/getInstalmentInfo',
  NKOLAY_CAPTURE_TRANSACTION: '/nkolay/captureTransaction',
  NKOLAY_REVERSE_TRANSACTION: '/nkolay/cancelOrRefundTransaction',
  NKOLAY_ADMIN_APPROVE_REVERSE_TRANSACTION: (paymentTransactionId: number, paymentTransactionRefundId: string) =>
    `/paynet/adminApproveReverseTransactionRequest/${paymentTransactionId}/${paymentTransactionRefundId}`,

  async tdsInitialPayment(
    formData: FormData,
    sourceType: PaymentSourceEnum,
  ): Promise<GenericResponse<PaynetInitialPaymentResponse>> {
    try {
      const url =
        sourceType == PaymentSourceEnum.NKOLAY ? this.NKOLAY_TDS_INITIAL_PAYMENT : this.PAYNET_TDS_INITIAL_PAYMENT;

      const response = await paymentClient.post(url, formData);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async createPaylink(formData: FormData): Promise<GenericResponse<PaynetCreatePayLinkResponse>> {
    try {
      const response = await paymentClient.post(this.PAYNET_CREATE_PAYLINK, formData);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async getInstalmentInfo(
    requestDto: PaynetGetInstalmentRequest,
    sourceType: PaymentSourceEnum,
  ): Promise<GenericResponse<PaynetGetInstalmentResponse>> {
    try {
      const url =
        sourceType == PaymentSourceEnum.NKOLAY ? this.NKOLAY_GET_INSTALMENT_INFO : this.PAYNET_GET_INSTALMENT_INFO;

      const response = await paymentClient.post(url, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async captureTransaction(
    requestDto: PaynetCaptureTransactionRequest,
    sourceType: PaymentSourceEnum,
  ): Promise<GenericResponse<PaynetCaptureTransactionResponse>> {
    try {
      const url =
        sourceType == PaymentSourceEnum.NKOLAY ? this.NKOLAY_CAPTURE_TRANSACTION : this.PAYNET_CAPTURE_TRANSACTION;

      const response = await paymentClient.post(url, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async reverseTransaction(
    requestDto: PaynetReverseTransactionRequest,
    sourceType: PaymentSourceEnum,
  ): Promise<GenericResponse<PaynetReverseTransactionResponse>> {
    try {
      const url =
        sourceType == PaymentSourceEnum.NKOLAY ? this.NKOLAY_REVERSE_TRANSACTION : this.PAYNET_REVERSE_TRANSACTION;

      const response = await paymentClient.post(url, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async adminApproveReverseTransactionRequest(
    paymentTransactionId: number,
    paymentTransactionRefundId: string,
  ): Promise<GenericResponse<any>> {
    try {
      const response = await paymentClient.post(
        this.PAYNET_ADMIN_APPROVE_REVERSE_TRANSACTION(paymentTransactionId, paymentTransactionRefundId),
      );
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },
};
