import FormSelect, { FormSelectProps } from 'core/components/FormSelect/FormSelect';
import React from 'react';
import { MenuItem } from '@material-ui/core';

interface ReportMonthSelectProps extends Omit<FormSelectProps, 'options'> {
  showAllMonth?: boolean;
}
const ReportMonthSelect: React.FC<ReportMonthSelectProps> = (selectProps: any, { showAllMonth }) => {
  return (
    <FormSelect label="Ay" placeholder="Ay" {...selectProps}>
      {selectProps.showAllMonth && <MenuItem value="0">Tüm Aylar</MenuItem>}
      <MenuItem value="1">Ocak</MenuItem>
      <MenuItem value="2">Şubat</MenuItem>
      <MenuItem value="3">Mart</MenuItem>
      <MenuItem value="4">Nisan</MenuItem>
      <MenuItem value="5">Mayıs</MenuItem>
      <MenuItem value="6">Haziran</MenuItem>
      <MenuItem value="7">Temmuz</MenuItem>
      <MenuItem value="8">Ağustos</MenuItem>
      <MenuItem value="9">Eylül</MenuItem>
      <MenuItem value="10">Ekim</MenuItem>
      <MenuItem value="11">Kasım</MenuItem>
      <MenuItem value="12">Aralık</MenuItem>
    </FormSelect>
  );
};
ReportMonthSelect.defaultProps = {
  showAllMonth: false,
};

export default ReportMonthSelect;
