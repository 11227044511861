import { combineReducers, getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import { authReducer, setToken, setUserInfo } from '../apps/auth/store';
import { Store, coreReducer } from '../core';

const createStore = (): Store => {
    const rootReducer = combineReducers({
        auth: authReducer,
        core: coreReducer,
    });

    const middleware = [
        ...getDefaultMiddleware({
            serializableCheck: false,
        }),
    ];

    const store = configureStore({
        reducer: rootReducer,
        middleware,
    });

    const token = localStorage.getItem('token');
    if (token) {
        const userInfoData = localStorage.getItem('userInfo');
        if (userInfoData) {
            try {
                const userInfo = JSON.parse(userInfoData);
                store.dispatch(setUserInfo(userInfo));
                store.dispatch(setToken(token));
                // store.dispatch(refreshUserInfoThunk());
            } catch (e) {
                console.log(e);
            }
        }
    }

    return {
        store,
        rootReducer,
    };
}

export default createStore;