import { createAction } from '@reduxjs/toolkit';
import {
  SHOW_TOAST,
  ToastProps,
  HIDE_TOAST,
  SHOW_BALANCE_PAYMENT_DIALOG,
  TOGGLE_DRAWER,
  TOGGLE_MOBILE_DRAWER,
  SHOW_FULL_SCREEN_LOADING,
  PaymentDialogProps,
  SET_PAYMENT_TRANSACTION_ID,
  SET_PAYMENT_AMOUNT,
  SET_IS_PACKAGE_PAYMENT,
  SET_IS_MEMBERSHIP_PAYMENT,
  SET_INSTALLMENT_TYPE,
  SET_PAYMENT_ORDER_TYPE,
} from './types';

export const showToast = createAction<ToastProps>(SHOW_TOAST);
export const hideToast = createAction(HIDE_TOAST);
export const showBalancePaymentDialog = createAction<PaymentDialogProps>(SHOW_BALANCE_PAYMENT_DIALOG);
export const toggleDrawer = createAction<boolean>(TOGGLE_DRAWER);
export const toggleMobileDrawer = createAction<boolean>(TOGGLE_MOBILE_DRAWER);
export const showFullscreenLoading = createAction<boolean>(SHOW_FULL_SCREEN_LOADING);
export const setPaymentTransactionId = createAction<number>(SET_PAYMENT_TRANSACTION_ID);
export const setIsPackagePayment = createAction<boolean>(SET_IS_PACKAGE_PAYMENT);
export const setIsMembershipPayment = createAction<boolean>(SET_IS_MEMBERSHIP_PAYMENT);
export const setInstallmentType = createAction<number>(SET_INSTALLMENT_TYPE);
export const setPaymentAmount = createAction<number>(SET_PAYMENT_AMOUNT);
export const setPaymentOrderType = createAction<number>(SET_PAYMENT_ORDER_TYPE);
