import { Switch, Route } from 'react-router-dom';
import { Layout } from "../Layout";
import React from 'react';
import { PaynetLayout } from "../../../apps/paynet";
import { AdminLayout } from "../../../apps/admin";
import { HomeLayout } from "apps/home";

const MainLayout: React.FC = () => {
    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={HomeLayout} />
                <Route strict path="/paynet" component={PaynetLayout} />
                <Route strict path="/admin" component={AdminLayout} />
            </Switch>
        </Layout>
    );
}

export default MainLayout;