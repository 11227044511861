import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage, ResetPasswordPage } from '.';

const AuthPage = () => {
  return (
    <Switch>
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/resetPassword/:token" component={ResetPasswordPage} />
      <Redirect from="/auth" exact to="/auth/login" />
      <Redirect to="/auth/login" />
    </Switch>
  );
};

export default AuthPage;
