import React from 'react';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { tr } from 'date-fns/locale';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import moment from 'moment';
import { createStaticRanges } from 'react-date-range';

interface DateRangePickerTableProps {
  setSelectionRange: (e: any) => void;
  selectionRange: any;
  setButtonShowLoading: (e: boolean) => void;
  formId: string;
  handleClose: (e: boolean) => void;
}
const DateRangePickerTable: React.FC<DateRangePickerTableProps> = ({
  setSelectionRange,
  selectionRange,
  setButtonShowLoading,
  formId,
  handleClose,
}) => {
  const [tempSelectionRange, setTempSelectionRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const staticRangesLabels = {
    'Today': 'Bugün',
    'Yesterday': 'Dün',
    'This Week': 'Bu hafta',
    'Last Week': 'Geçen hafta',
    'This Month': 'Bu ay',
    'Last Month': 'Geçen ay',
  };

  const handleSelect = (ranges: any) => {
    setTempSelectionRange(ranges.selection);
  };

  const onSubmit = () => {
    const formattedSelectionRange = {
      startDate: moment(tempSelectionRange.startDate).toLocaleString(),
      endDate: moment(tempSelectionRange.endDate).toLocaleString(),
      key: 'selection',
    };
    setSelectionRange(formattedSelectionRange);
    handleClose(false);
  };

  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastYear: startOfYear(new Date()),
    endOfLastYear: startOfYear(addYears(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
  };

  const defaultInputRanges = [
    {
      label: 'Bugüne kadar olan günler',
      range(value: any) {
        return {
          startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
          endDate: defineds.endOfToday,
        };
      },
      getCurrentValue(range: any) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
        if (!range.startDate) return '∞';
        return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
      },
    },
  ];

  const defaultStaticRanges = createStaticRanges([
    {
      label: 'Bugün',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Dün',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },

    {
      label: 'Bu hafta',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },
    {
      label: 'Geçen hafta',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    },
    {
      label: 'Bu ay',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: 'Geçen ay',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: 'Bu yıl',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    },
    {
      label: 'Geçen yıl',
      isSelected: (range) => false,
      range: () => ({
        startDate: defineds.endOfLastYear,
        endDate: defineds.startOfLastYear,
      }),
    },
  ]);

  const inputRangesLabels = {
    'days up to today': 'Bugüne kadar olan günler',
  };

  const translateRange = (dictionary: any) => {
    return (item: any) => (dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item);
  };

  const trInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

  const trStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
  return (
    <React.Fragment>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          temp: '',
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, errors, values, handleChange }) => (
          <form noValidate onSubmit={handleSubmit} id={formId}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DateRangePicker
                  ranges={[tempSelectionRange]}
                  onChange={handleSelect}
                  locale={tr}
                  staticRanges={trStaticRanges}
                  inputRanges={trInputRanges}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default DateRangePickerTable;
