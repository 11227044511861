import { FormDialog } from 'core';
import { DateRangePickerTable } from 'core/components/DateRangePicker';
import React from 'react';

export interface DateRangePickerDialogProps {
  showDateRangePickerDialog: any;
  setShowDateRangePickerDialog: (e: boolean) => void;
  setSelectionRange: any;
  selectionRange: any;
}
const DateRangePickerDialog: React.FC<DateRangePickerDialogProps> = ({
  setShowDateRangePickerDialog,
  showDateRangePickerDialog,
  setSelectionRange,
  selectionRange,
}) => {
  const [buttonShowLoading, setButtonShowLoading] = React.useState(false);
  return (
    <React.Fragment>
      <FormDialog
        open={showDateRangePickerDialog}
        handleClose={() => setShowDateRangePickerDialog(false)}
        formId="dateRangePicker"
        title="Tarih Filtreleme"
        showLoading={buttonShowLoading}
      >
        <DateRangePickerTable
          formId="dateRangePicker"
          setSelectionRange={setSelectionRange}
          selectionRange={selectionRange}
          setButtonShowLoading={setButtonShowLoading}
          handleClose={setShowDateRangePickerDialog}
        />
      </FormDialog>
    </React.Fragment>
  );
};

export default DateRangePickerDialog;
