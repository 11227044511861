enum PaymentTransactionRefundStatusEnum {
  CREATED = 0,
  COMPLETED = 1,
  REJECTED = 2,
  // Ana bayi'den çekilen işlemlerin iade/iptal edilmek istendiğinde admin onayı için ilk once bu statüye geçer
  WAITING_ADMIN_APPROVAL = 3,
  // Ana bayi'den çekilen işlemlerin iade/iptal edilmek istendiğinde admin onayı için ilk once bu statüye geçer
  APPROVED_BY_ADMIN = 4,
  REJECTED_BY_ADMIN = 5, // Ana bayi iptal/iade'lerini admin reddederse bu statüye geçer.
}

export default PaymentTransactionRefundStatusEnum;
