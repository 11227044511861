import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Fade,
} from '@material-ui/core';
import React from 'react';
import { LoadingButton } from 'reactmuicommon';

// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@material-ui/core/transitions/transition';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

export interface ConfirmDialogProps extends DialogProps {
  title: string;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  showLoading?: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  children,
  handleClose,
  handleConfirm,
  cancelText,
  confirmText,
  showLoading,
  ...dialogProps
}) => (
  <Dialog open={open} onClose={handleClose} {...dialogProps} TransitionComponent={Transition}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="inherit">
        {cancelText}
      </Button>
      <LoadingButton onClick={handleConfirm} color="primary" autoFocus showLoading={showLoading}>
        {confirmText}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.defaultProps = {
  confirmText: 'Onay',
  cancelText: 'İptal',
};

export default ConfirmDialog;
