import { TextFieldProps, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

export type FormTextFieldProps = TextFieldProps;

const NewFormTextField: React.FC<FormTextFieldProps> = ({
  InputLabelProps,
  placeholder,
  label,
  fullWidth,
  ...textFieldProps
}) => {
  const _InputLabelProps = InputLabelProps ? { shrink: true, ...InputLabelProps } : { shrink: true };
  return (
    <TextField
      fullWidth={fullWidth || true}
      variant="outlined"
      margin="dense"
      {...textFieldProps}
      InputLabelProps={_InputLabelProps}
      label={label || placeholder}
      placeholder={placeholder}
    />
  );
};

export default NewFormTextField;

export const maskedCreditCard = (props: any) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="####-####-####-####"
      mask="_"
    />
  );
};

export const maskedValidDate = (props: any) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##/##"
      mask="_"
    />
  );
};

export const maskedCvc = (props: any) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###"
      mask="_"
    />
  );
};
