// eslint-disable-next-line no-shadow
export enum PaymentSourceEnum {
  PAYNET = 0,
  NKOLAY = 10,
}

export const PaymentSourceEnumLabel = new Map<number, string>([
  [PaymentSourceEnum.PAYNET, 'PAYNET'],
  [PaymentSourceEnum.NKOLAY, 'NKolay'],
]);
