import { DropzoneDialog } from 'material-ui-dropzone';
import React from 'react';

export interface FileUploaderModalProps {
  onSave: (files: any) => void;
  onClose?: () => void;
  open: boolean;
  text?: string;
  maxFileSize?: number;
  showFileNames?: boolean;
  acceptedFiles?: string[];
}

const FileUploaderModal: React.FC<FileUploaderModalProps> = ({
  onSave,
  onClose,
  open,
  acceptedFiles,
  text,
  maxFileSize,
  showFileNames,
}) => {
  return (
    <DropzoneDialog
      onSave={onSave}
      onClose={onClose}
      open={open}
      acceptedFiles={acceptedFiles}
      cancelButtonText="İptal"
      submitButtonText="Kaydet"
      dropzoneText={text ?? 'Dosyaları sürükle-bırak yaparak veya buraya tıklayarak yükleyebilirsin'}
      maxFileSize={maxFileSize ?? 5000000}
      showFileNames={showFileNames ?? true}
      getFileAddedMessage={(fileName: string) => `Dosya ${fileName} başarıyla eklendi`}
      getFileLimitExceedMessage={(filesLimit: number) => `Maksimuum ${filesLimit} adet dosya ekleyebilirsiniz`}
      getFileRemovedMessage={(fileName: string) => `Dosya ${fileName} başarıyla eklendi`}
    />
  );
};

export default FileUploaderModal;
