import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { GetUsersRequestDto } from 'apps/admin';
import { UserInfo } from 'apps/auth';
import {
  Api,
  ConfirmDialog,
  exportToExcel,
  exportToPdf,
  getSortingQuery,
  PaginationQuery,
  showToast,
  TranslatedMaterialTable,
} from 'core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const UserListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [columns, setColumns] = React.useState<any[]>();
  const [reLoadTable, setReLoadTable] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<UserInfo | null>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const actions: any[] = [
    (rowData: UserInfo) => ({
      icon: 'edit',
      tooltip: 'Düzenle',
      onClick: (event: any, data: any) => {
        setSelectedUser(data as UserInfo);
        history.push(`/admin/user/edit/${rowData.id}`);
      },
    }),
    // (rowData: UserInfo) => ({
    //   icon: 'delete',
    //   tooltip: 'Sil',
    //   onClick: (event: any, data: any) => {
    //     setSelectedUser(data as UserInfo);
    //     setShowDeleteDialog(true);
    //   },
    // }),
  ];

  const getColumns = (): any[] => {
    return [
      {
        title: 'Bayi Adı',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'agentName',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'Mail',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'email',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'Paynet Bayi No',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'paynetAgentId',
        sorting: true,
        filtering: false,
        searching: false,
      },
    ];
  };

  const handleDialogClose = () => {
    setShowDeleteDialog(false);
  };

  const deleteUser = async () => {
    handleDialogClose();
    setShowLoading(true);
    if (selectedUser) {
      const response = await Api.Auth.deleteUser(selectedUser.id);
      if (response.isSuccess) {
        setReLoadTable(true);
        dispatch(
          showToast({
            message: `Kullanıcı başarıyla silindi.`,
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    }
    setShowDeleteDialog(false);
    setShowLoading(false);
    setSelectedUser(null);
    setReLoadTable(false);
  };

  const getUsers = async (query: any) => {
    const { orderBy, orderDirection, page, pageSize, search } = query;
    try {
      setShowLoading(true);

      const requestDto = {
        paginationQuery: { page, pageSize } as PaginationQuery,
        sortingQuery: getSortingQuery(orderBy, orderDirection),
        searchText: search,
      } as GetUsersRequestDto;

      const response = await Api.Auth.getUsers(requestDto);
      if (response.isSuccess) {
        const { result } = response;
        return {
          data: result?.data ?? [],
          page,
          totalCount: result?.totalCount ?? 0,
        };
      }
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${e.message ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }

    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  };

  return (
    <React.Fragment>
      <br />
      <br />
      <IconButton onClick={() => history.goBack()}>
        <ArrowBack />
      </IconButton>
      {!reLoadTable && (
        <TranslatedMaterialTable
          title="Kullanıcı Listesi"
          columns={columns ?? getColumns()}
          data={getUsers}
          actions={actions}
          isLoading={showLoading}
          options={{
            filtering: true,
            exportAllData: true,
            exportButton: true,
            exportCsv: exportToExcel,
            // @ts-ignore
            exportPdf: exportToPdf,
          }}
        />
      )}
      <ConfirmDialog
        title="Kullanıcıyı Sil"
        open={showDeleteDialog}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={deleteUser}
        handleClose={handleDialogClose}
      >
        {selectedUser && `${selectedUser.agentName} kullanıcısını silmek istediğinize emin misiniz?`}
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default UserListPage;
