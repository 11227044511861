import { GenericResponse, paymentClient } from '../../../core';
import { HomeStatistics } from '../models';

export default {
  URL_STATISTICS: '/payment/getStatistics',

  async getHomeStatistics(startDate: string, endDate: string): Promise<GenericResponse<HomeStatistics>> {
    try {
      const response = await paymentClient.post(this.URL_STATISTICS, { startDate, endDate });
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<HomeStatistics>;
    }
  },
};
