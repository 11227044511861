import { Color } from '@material-ui/lab/Alert';
import { SnackbarOrigin } from '@material-ui/core';

export const SHOW_TOAST = 'core/showToast';
export const HIDE_TOAST = 'core/hidetoast';
export const REFRESH_GLOBAL_DEALER_BALANCE = 'core/refreshGlobalDealerBalance';
export const SHOW_BALANCE_PAYMENT_DIALOG = 'core/showBalancePaymentDialog';
export const TOGGLE_DRAWER = 'core/toggleDrawer';
export const SET_NOTIFICATIONS = 'core/setNotifications';
export const TOGGLE_MOBILE_DRAWER = 'core/toggleMobileDrawer';
export const ANNOUNCEMENTS = 'core/announcements';
export const SHOW_FULL_SCREEN_LOADING = 'core/showFullscreenLoading';
export const SET_PAYMENT_TRANSACTION_ID = 'core/setPaymentTransactionId';
export const SET_IS_PACKAGE_PAYMENT = 'core/setIsPackagePayment';
export const SET_IS_MEMBERSHIP_PAYMENT = 'core/setIsMembershipPayment';
export const SET_INSTALLMENT_TYPE = 'core/setInstallmentType';
export const SET_PAYMENT_AMOUNT = 'core/setPaymentAmount';
export const SET_PAYMENT_ORDER_TYPE = 'core/setPaymentOrderType';

export interface ToastProps {
  autoHideDuration?: number;
  severity?: Color;
  message: string;
  anchorOrigin?: SnackbarOrigin;
}

export interface CoreState {
  toastProps: ToastProps;
  showToast: boolean;
  dealerBalance?: number;
  isBalancePaymentDialogOpen: boolean;
  isDrawerOpen: boolean;
  unreadCount: number;
  isMobileDrawerOpen: boolean;
  unreadAnnouncementCount: number;
  showFullscreenLoading: boolean;
  paymentDialogProps: PaymentDialogProps;
  paymentTransactionId?: number;
  paymentAmount?: number;
  isPackagePayment: boolean;
  isMembershipPayment: boolean;
  installmentType?: number;
  paymentOrderType?: number;
}

export interface PaymentDialogProps {
  show: boolean;
  typeId: PaymentTypeEnum;
  amount?: number;
  showAmount?: boolean;
  title?: string;
  reloadOnFinish?: boolean;
  onPaymentComplete?: () => void;
  onPaymentFail?: () => void;
}

enum PaymentTypeEnum {
  SUBSCRIPTION = 1,
  BALANCE = 2,
  MODULES = 3,
  VIRTUALPOS = 4,
}

export const MaterialTableCurrencySettings = {
  locale: 'tr',
  currencyCode: 'try',
};

export default PaymentTypeEnum;
