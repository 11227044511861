import { Home, Money } from '@material-ui/icons';
import React from 'react';

const paynetSidebarMenuList = {
  id: 'paynetMenus',
  title: '',
  child: [
    {
      id: 'paynetTransactions',
      label: 'İşlemler',
      icon: <Money />,
      child: [
        {
          id: 'getPaymentWithPaynet',
          label: 'Ödeme Al',
          icon: <Money />,
          route: '/paynet/payment/',
        },
        {
          id: 'paynetPaymentList',
          label: 'Ödemelerim',
          icon: <Money />,
          route: '/paynet/payment/list/',
        },
      ],
    },
  ],
};

export default paynetSidebarMenuList;
