import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router';

const Back = () => {
  const history = useHistory();
  return (
    <IconButton onClick={() => history.goBack()}>
      <ArrowBack />
    </IconButton>
  );
};

export default Back;
