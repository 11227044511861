import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { Snackbar, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Warning as WarningIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { CoreState, hideToast, MainLayout, IFrameAuthPage, ConsentFilePage } from './core';
import { AuthPage } from './apps/auth';
import { AuthState } from './apps/auth/store';

const RedChip = withStyles({
  root: {
    backgroundColor: '#d8201c',
    color: 'white',
    style: 'bold',
    position: 'fixed',
    bottom: '16px',
    left: '16px',
    zIndex: 99999999,
  },
  icon: {
    color: 'white',
  },
})(Chip);

const Routes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthorized, paymentTransactionId } = useSelector(
    ({ auth, core }: { auth: AuthState; core: CoreState }) => ({
      isAuthorized:
        auth.token != null && auth.token != undefined && auth.userInfo != null && auth.userInfo != undefined,
      paymentTransactionId: core.paymentTransactionId,
    }),
    shallowEqual,
  );

  const { showToast, toastProps } = useSelector(
    (state: any) => ({
      showToast: state.core.showToast,
      toastProps: state.core.toastProps,
    }),
    shallowEqual,
  );

  const handleToastClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideToast());
  };

  return (
    <React.Fragment>
      {process.env.REACT_APP_TESTING && <RedChip label="Test Sürümü" icon={<WarningIcon />} />}
      <Snackbar
        open={showToast}
        autoHideDuration={toastProps.autoHideDuration}
        onClose={handleToastClose}
        anchorOrigin={toastProps.anchorOrigin}
      >
        <Alert severity={toastProps.severity} onClose={handleToastClose}>
          {toastProps.message}
        </Alert>
      </Snackbar>

      {location.pathname.startsWith('/cf/') && (
        <Switch>
          <Route exact path="/cf/:fileUUID" component={ConsentFilePage} />
        </Switch>
      )}

      {location.pathname === '/iframeAuth' && !location.pathname.startsWith('/cf/') && (
        <Switch>
          <Route>
            <IFrameAuthPage />
          </Route>
        </Switch>
      )}
      {location.pathname != '/iframeAuth' && !location.pathname.startsWith('/cf/') && (
        <Switch>
          {!isAuthorized ? (
            <Route>
              <AuthPage />
            </Route>
          ) : (
            <Redirect from="/auth" to="/" />
          )}

          {!isAuthorized ? <Redirect to="/auth/login" /> : <MainLayout />}
        </Switch>
      )}
    </React.Fragment>
  );
};

export default Routes;
