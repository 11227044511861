import { Api } from 'core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HideableLoadingSpinnerBox } from 'reactmuicommon';
import { showToast } from 'core/store';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

const ConsentFilePage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [pdfFileLink, setPdfFileLink] = React.useState<string>();
  const [numPages, setNumPages] = React.useState(null);
  const { fileUUID } = useParams<any>();

  const openAgreementLink = async () => {
    setIsLoading(true);
    if (fileUUID) {
      const response = await Api.getConsentFileLink(fileUUID);
      if (response.isSuccess && response.result) {
        setPdfFileLink(response.result);
      } else {
        dispatch(
          showToast({
            message: `Muvafakatnameniz gösterilirken bir sorun oluştu.`,
            severity: 'error',
          }),
        );
      }
    }
    setIsLoading(false);
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  React.useEffect(() => {
    openAgreementLink();
  }, [fileUUID]);

  if (isLoading) {
    return <HideableLoadingSpinnerBox />;
  }

  if (pdfFileLink) {
    return (
      <React.Fragment>
        <div className="all-page-container">
          <Document file={pdfFileLink} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default ConsentFilePage;
