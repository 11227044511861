import { Box, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import { LoadingButton } from 'reactmuicommon';
import { Api, FileUploader, showToast } from 'core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { UserInfo } from 'apps/auth';

const formSchema = yup.object({
  email: yup.string().email().required('Boş olamaz'),
  paynetAgentId: yup.string().required('Boş olamaz'),
  agentName: yup.string().required('Boş olamaz'),
});

const RegisterUserPage = () => {
  // const initialValues = { email: '', paynetAgentId: '', agentName: '' };

  const [showLoading, setShowLoading] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = React.useState<any>();
  const [initialValues, setInitialValues] = React.useState<any>({});
  const [selectedUser, setSelectedUser] = React.useState<UserInfo | null>(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { id }: any = useParams();

  React.useEffect(() => {
    Api.Auth.getUserInfoById(id)
      .then((response) => {
        if (!response.isSuccess || !response.result) {
          dispatch(
            showToast({
              message: `Kullanıcı mevcut değil!`,
              severity: 'error',
            }),
          );
          history.push('/admin/users');
        } else {
          setSelectedUser(response.result);
          setInitialValues({
            agentName: response.result.agentName,
            paynetAgentId: response.result.paynetAgentId,
            email: response.result.email,
          });
        }
      })
      .catch((err) => {
        dispatch(
          showToast({
            message: `Hata: ${err.message}`,
            severity: 'error',
          }),
        );
        history.push('/admin/users');
      });
  }, [id]);

  const onSubmit = async (formData: any, { resetForm }: any) => {
    const request = new FormData();
    if (id && selectedUser && !selectedLogo) {
      request.append('logoUrl', selectedUser.logoUrl ?? '');
    } else {
      request.append('logo', selectedLogo);
    }

    request.append('email', formData.email);
    request.append('paynetAgentId', formData.paynetAgentId);
    request.append('agentName', formData.agentName);

    setShowLoading(true);

    try {
      const response = await Api.Auth.updateUser(request);
      if (response.isSuccess && response.result) {
        resetForm({});
        dispatch(
          showToast({
            message: `Kullanıcı başarıyla güncellendi!`,
          }),
        );
        history.push('/admin/users');
      } else {
        dispatch(
          showToast({
            message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    } catch (e) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${e.message ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    }

    setShowLoading(false);
  };

  const onDroppedFilesCreditCard = async (files: any) => {
    setSelectedLogo(files[0]);
  };

  return (
    <React.Fragment>
      <Box style={{ width: '100%' }}>
        <Card>
          <CardHeader title="Kullanıcı Bilgilerini Güncelle" />
          <Divider />
          <Formik
            onSubmit={onSubmit}
            validationSchema={formSchema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              email: selectedUser?.email ?? '',
              paynetAgentId: selectedUser?.paynetAgentId ?? '',
              agentName: selectedUser?.agentName ?? '',
            }}
            enableReinitialize
          >
            {({ handleSubmit, handleChange, errors, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <CardContent>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              required
                              id="outlined-basic"
                              label="Email Adresi"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              disabled
                              placeholder="Email Adresi"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              error={errors.email != null}
                              helperText={errors.email}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              required
                              id="outlined-basic"
                              label="Bayi Adı"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              placeholder="Bayi Adı"
                              name="agentName"
                              onChange={handleChange}
                              value={values.agentName}
                              error={errors.agentName != null}
                              helperText={errors.agentName}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              required
                              id="outlined-basic"
                              label="Paynet Bayi Id"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              placeholder="Paynet Bayi Id"
                              name="paynetAgentId"
                              onChange={handleChange}
                              value={values.paynetAgentId}
                              error={errors.paynetAgentId != null}
                              helperText={errors.paynetAgentId}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <FileUploader
                          maxFilesCount={1}
                          text="Bayinin logosunu buraya yükleyebilirsiniz"
                          onChange={onDroppedFilesCreditCard}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <LoadingButton
                          color="primary"
                          type="submit"
                          variant="contained"
                          disableElevation
                          showLoading={showLoading}
                        >
                          Kaydet
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </form>
            )}
          </Formik>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default RegisterUserPage;
