import React from 'react';
import { Route } from 'react-router';
import { SubscriptionSwitch } from '../../../core';
import { PaynetPaymentListPage, PaynetPaymentPage } from '../pages';

export interface PaynetLayoutProps {
  match?: any;
}

const PaynetLayout: React.FC<PaynetLayoutProps> = ({ match }) => {
  return (
    <SubscriptionSwitch match={match} subscriptionLevel={0}>
      {/* <PermissionRoute
          exact
          path={`${match.path}`}
          component={InsuranceListPage}
          permissions={[PermissionType.SYSTEM_INSURER, PermissionType.SYS_ADMIN, PermissionType.INSURANCE]}
        /> */}
      {/* <PermissionRoute
          exact
          path={`${match.path}/response/:id/:typeId`}
          component={InsuranceResponsePage}
          permissions={[PermissionType.SYSTEM_INSURER, PermissionType.SYS_ADMIN, PermissionType.INSURANCE]}
        /> */}
      <Route exact path={`${match.path}/payment`} component={PaynetPaymentPage} />
      <Route exact path={`${match.path}/payment/list`} component={PaynetPaymentListPage} />

    </SubscriptionSwitch>
  );
};

export default PaynetLayout;