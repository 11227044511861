/* eslint-disable no-console */
import { setUserInfo, setToken, logout } from 'apps/auth/store';
import {
  setPaymentTransactionId,
  setPaymentAmount,
  showToast,
  Api,
  setIsPackagePayment,
  setIsMembershipPayment,
  setInstallmentType,
  setPaymentOrderType,
} from 'core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { HideableLoadingSpinnerBox } from 'reactmuicommon';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const IFrameAuthPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [
    token,
    paymentTransactionId,
    paymentAmount,
    isPackagePayment,
    isMembershipPayment,
    installmentType,
    paymentOrderType,
  ]: any = [
    query.get('token'),
    query.get('paymentTransactionId'),
    query.get('paymentAmount'),
    query.get('isPackagePayment'),
    query.get('isMembershipPayment'),
    query.get('installmentType'),
    query.get('orderType'),
  ];

  React.useEffect(() => {
    if (
      !token ||
      !paymentTransactionId ||
      Number.isNaN(+paymentTransactionId) ||
      !paymentAmount ||
      Number.isNaN(+paymentAmount) ||
      (isMembershipPayment?.toLowerCase() === 'true' &&
        (!installmentType || installmentType <= 0 || installmentType > 2))
    ) {
      console.error(`Missing properties. token, paymentTransactionId and paymentAmount must be sent!`, {
        token,
        paymentTransactionId,
        paymentAmount,
      });
      dispatch(
        showToast({
          message: `Missing properties. token, paymentTransactionId and paymentAmount must be sent!`,
          severity: 'error',
        }),
      );
      return;
    }

    dispatch(setToken(token));
    Api.Auth.getUserInfo()
      .then((response) => {
        if (!response.isSuccess || !response.result) {
          console.error(`Token is not valid!`, token);
          dispatch(logout());
          dispatch(
            showToast({
              message: `Token is not valid!`,
              severity: 'error',
            }),
          );
          return;
        }
        dispatch(setPaymentTransactionId(paymentTransactionId));
        dispatch(setIsPackagePayment(isPackagePayment?.toLowerCase() === 'true'));
        dispatch(setIsMembershipPayment(isMembershipPayment?.toLowerCase() === 'true'));
        dispatch(setInstallmentType(installmentType));
        dispatch(setPaymentAmount(paymentAmount));
        dispatch(setUserInfo(response.result));
        dispatch(setPaymentOrderType(paymentOrderType));
        localStorage.setItem('isOpenIframe', 'true');
        history.push('/paynet/payment');
      })
      .catch((err) => {
        console.error(`Unexpected Error`, err);
        dispatch(logout());
        dispatch(
          showToast({
            message: `Unexpected Error: ${err.message}`,
            severity: 'error',
          }),
        );
      });
  }, []);
  return <HideableLoadingSpinnerBox />;
};

export default IFrameAuthPage;
