/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import {
  GenericResponse,
  PaymentTransactionRefundStatusEnum,
  PaymentTransactionStatusEnum,
  PaymentTypeEnum,
  SortingQuery,
} from 'core/models';
import JSZip from 'jszip';

export const getPaymentTypeEnumString = (value: PaymentTypeEnum): string => {
  switch (value) {
    case 0:
      return '3D';
    case 1:
      return 'OccoLink';
    default:
      return '';
  }
};

export const getPaymentTransactionStatusEnumString = (value: PaymentTransactionStatusEnum): string => {
  if (value == 0) {
    return 'Bekliyor/Provizyon';
  }
  if (value == 1) {
    return 'Tamamlandı';
  }
  if (value == 2) {
    return 'Zaman Aşımı';
  }
  if (value == 3) {
    return 'Başarısız';
  }
  if (value == 5) {
    return 'İptal/İade Edildi';
  }
  if (value == 6) {
    return 'İptal/İade Talebi Oluşturuldu';
  }
  if (value == 7) {
    return 'İptal/İade Talebi Reddedildi';
  }
  if (value == 8) {
    return 'Parçalı İptal/İade Edildi';
  }
  return '';
};

export const getPaymentTransactionRefundStatusEnumString = (value: PaymentTransactionRefundStatusEnum): string => {
  if (value == 1) {
    return 'Tamamlandı';
  }
  if (value == 2) {
    return 'Başarısız';
  }
  if (value == 3) {
    return 'Admin Onayı Bekliyor';
  }
  if (value == 4) {
    return 'Admin Onayladı';
  }
  if (value == 5) {
    return 'Admin Red';
  }
  return '';
};

export const downloadFilesFromUrlToZip = async (urls: string[], zipFileName: string): Promise<GenericResponse<any>> => {
  try {
    if (!Array.isArray(urls) || urls.length == 0) {
      return { isSuccess: false, errorMessage: 'urls parametresi boş olamaz.' } as GenericResponse;
    }

    const zip = new JSZip();
    const dataList = await urls.map(async (url: string, index: number) => {
      const response = await fetch(url);
      const data = await response.blob();

      const pieces = url.split('/');
      const fileName = pieces[pieces.length - 1].toString();
      zip.file(fileName, data);
      return data;
    });

    return await Promise.all(dataList)
      .then(async () => {
        try {
          const content = await zip.generateAsync({ type: 'blob' });
          saveAs(content, zipFileName);
          return { isSuccess: true } as GenericResponse;
        } catch (e) {
          return { isSuccess: false, errorMessage: e.message } as GenericResponse;
        }
      })
      .catch((e) => {
        return { isSuccess: false, errorMessage: e.message } as GenericResponse;
      });
  } catch (err) {
    return { isSuccess: false, errorMessage: err.message } as GenericResponse;
  }
};

export const getPaymentTransactionStatusEnumForLookup = () => {
  const result: any = {};
  Object.keys(PaymentTransactionStatusEnum)
    .filter((value) => isNaN(Number(value)) === false && Number(value) != Number(PaymentTransactionStatusEnum.EMPTY))
    .map((val: any) => (result[val] = getPaymentTransactionStatusEnumString(val)));

  return result;
};

export const getPaymentTransactionRefundStatusEnumForLookup = () => {
  const result: any = {};
  Object.keys(PaymentTransactionRefundStatusEnum)
    .filter(
      (value) => isNaN(Number(value)) === false && Number(value) != Number(PaymentTransactionRefundStatusEnum.CREATED),
    )
    .map((val: any) => (result[val] = getPaymentTransactionRefundStatusEnumString(val)));

  return result;
};

export const getSortingQuery = (orderBy: any, orderDirection: 'asc' | 'desc') => {
  if (orderBy == undefined || orderBy == null) {
    return null;
  }

  const sorting = {
    orderBy: orderBy.field as string,
    order: orderDirection.toUpperCase(),
  } as SortingQuery;

  return sorting;
};

export const getDisabledStatusForTransactionSlip = (status: PaymentTransactionStatusEnum) => {
  return (
    status === PaymentTransactionStatusEnum.EMPTY ||
    status === PaymentTransactionStatusEnum.PRE_AUTH_OR_WAITING_PAYMENT ||
    status === PaymentTransactionStatusEnum.TIMEOUT ||
    status === PaymentTransactionStatusEnum.FAILED
  );
};

export const mainAgentId = '1303';

export const pelielAgentId = '79513';

export const isTekbasAgent = (paynetAgentId: string): boolean => {
  return ['71394', '71395', '71396'].indexOf(paynetAgentId) > -1;
};

export const isMainAgent = (paynetAgentId: string): boolean => {
  return [mainAgentId, pelielAgentId].indexOf(paynetAgentId) > -1;
};

export const getCommissionRate = (paynetAgentId: string, isAdminUser: boolean): number => {
  return isMainAgent(paynetAgentId) || isAdminUser ? 0 : isTekbasAgent(paynetAgentId) ? 0.01 : 0.02;
};
