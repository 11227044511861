import { SubscriptionSwitch } from 'core';
import React from 'react';
import { Route } from 'react-router';
import { EditUserPage, PaymentListPage, RegisterUserPage, UserListPage, WaitingRefundApprovalListPage } from '../pages';

export interface AdminLayoutProps {
  match?: any;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ match }) => {
  return (
    <SubscriptionSwitch match={match} subscriptionLevel={0}>
      {/* <PermissionRoute
          exact
          path={`${match.path}`}
          component={InsuranceListPage}
          permissions={[PermissionType.SYSTEM_INSURER, PermissionType.SYS_ADMIN, PermissionType.INSURANCE]}
        /> */}
      {/* <PermissionRoute
          exact
          path={`${match.path}/response/:id/:typeId`}
          component={InsuranceResponsePage}
          permissions={[PermissionType.SYSTEM_INSURER, PermissionType.SYS_ADMIN, PermissionType.INSURANCE]}
        /> */}
      <Route exact path={`${match.path}/payment/list`} component={PaymentListPage} />
      <Route
        exact
        path={`${match.path}/payment/waitingRefundApproval/list`}
        component={WaitingRefundApprovalListPage}
      />
      <Route exact path={`${match.path}/user/register`} component={RegisterUserPage} />
      <Route exact path={`${match.path}/user/edit/:id`} component={EditUserPage} />
      <Route exact path={`${match.path}/users`} component={UserListPage} />
    </SubscriptionSwitch>
  );
};

export default AdminLayout;
