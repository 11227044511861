/* eslint-disable eqeqeq */
import React from 'react';
import { Api, FormDialog } from 'core';
import { Formik, Form } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { showToast } from 'core/store';
import { useDispatch } from 'react-redux';

interface ApproveConsentFileDialogProps {
  open: boolean;
  paymentTransactionId: number;
  handleClose: () => void;
}
const ApproveConsentFileDialog: React.FC<ApproveConsentFileDialogProps> = ({
  open,
  paymentTransactionId,
  handleClose,
}) => {
  const [showLoading, setShowLoading] = React.useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = async (values: any) => {
    setShowLoading(true);

    try {
      const response = await Api.approveConsentFile(paymentTransactionId, values.approveCode);
      if (!response.isSuccess) {
        dispatch(
          showToast({
            message: response.errorMessage ?? 'Hata Oluştu',
            severity: 'error',
          }),
        );
        return;
      }

      dispatch(
        showToast({
          message: 'Muvafakatname başarılıya onaylandı.',
          severity: 'success',
        }),
      );
      handleClose();
    } catch (error: any) {
      console.log(error.message);
      dispatch(
        showToast({
          message: 'Hata Oluştu',
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <React.Fragment>
      <FormDialog
        formId="approveConsentFile"
        open={open}
        confirmButtonText="Onayla"
        title="Muvafakatname Sözleşmesini Onayla"
        handleClose={() => {
          handleClose();
        }}
        onSubmit={onSubmit}
        showLoading={showLoading}
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            approveCode: '',
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, values }) => (
            <Form onSubmit={handleSubmit} id="approveConsentFile">
              Kart sahibinin telefonuna gelen 6 haneli onay kodunu giriniz.
              <Grid container spacing={2} style={{ minWidth: `${isMobile ? 'auto' : '400px'}`, minHeight: '100px' }}>
                <Grid item>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>6 Haneli Onay Kodu</InputLabel>
                    <OutlinedInput
                      id="cardHolder"
                      type="number"
                      margin="dense"
                      label="6 Haneli Onay Kodu"
                      name="approveCode"
                      value={values.approveCode}
                      required
                      onChange={handleChange}
                      placeholder="6 Haneli Onay Kodu"
                    />
                    <FormHelperText error>{errors.approveCode}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormDialog>
    </React.Fragment>
  );
};

export default ApproveConsentFileDialog;
