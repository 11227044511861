import { createAsyncThunk } from '@reduxjs/toolkit';
import { LOGIN, LoginActionPayload, REFRESH_USER_INFO } from './types';
import { UserInfo } from '../models';

export const loginActionThunk = createAsyncThunk(LOGIN, async ({ email, password }: LoginActionPayload) => {
  return {
    token: 'asdasdasdasd',
    userInfo: {
      fullName: 'test',
      email: 'test',
    },
  };
});

// export const refreshUserInfoThunk = createAsyncThunk(REFRESH_USER_INFO, async () => {
//   const response = await Api.Auth.refreshUserInfo();
//   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//   const userInfo: UserInfo = response.data!;
//   return userInfo;
// });
