/* eslint-disable eqeqeq */
import React from 'react';
import { Api, FormDialog, SendConsentFileRequestDto } from 'core';
import { Formik, Form } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { showToast } from 'core/store';
import { useDispatch } from 'react-redux';
import { FormMaskedTextField } from 'reactmuicommon';
import * as yup from 'yup';
import { ApproveConsentFileDialog } from '../ApproveConsentFileDialog';

interface SendConsentFileDialogProps {
  open: boolean;
  paymentTransactionId: number;
  handleClose: () => void;
}

const formSchema = yup.object({
  cardHolderGovernmentId: yup.string().length(11, '11 haneli olmalı'),
  cardHolderPhoneNumber: yup
    .string()
    .transform((v) => v.replaceAll('_', '').replaceAll('-', ''))
    .required('Boş olamaz!')
    .length(10, '10 haneli şekilde giriniz'),
  agentCommercialTitle: yup.string().required('Boş olamaz!'),
  customerName: yup.string().required('Boş olamaz!'),
  plateNumber: yup.string().required('Boş olamaz!'),
});

const SendConsentFileDialog: React.FC<SendConsentFileDialogProps> = ({ open, paymentTransactionId, handleClose }) => {
  const [showApproveConsentDialog, setShowApproveConsentDialog] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [gsmQueriesFindeks, setGsmQueriesFindeks] = React.useState<any>([]);

  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const comparePhoneNumbersWithFindeksControl = (input: any) => {
    return gsmQueriesFindeks?.some((item: any) => {
      const itemTelNo = item.telNo.replace(/[^\d]/g, '');
      const itemFirstThreeDigits = itemTelNo.slice(0, 3);
      const itemLastTwoDigits = itemTelNo.slice(-2);

      const inputFirstThreeDigits = input.slice(0, 3);
      const inputLastTwoDigits = input.slice(-2);

      return itemFirstThreeDigits === inputFirstThreeDigits && itemLastTwoDigits === inputLastTwoDigits;
    });
  };

  const onSubmit = async (values: any) => {
    const isMatch = comparePhoneNumbersWithFindeksControl(values.cardHolderPhoneNumber);
    if (!isMatch) {
      dispatch(
        showToast({
          message: 'Tel no yu yanlış girdiniz. Lütfen kontrol ediniz',
          severity: 'error',
        }),
      );
      return;
    }
    setShowLoading(true);
    try {
      const requestData = {
        paymentTransactionId,
        agentCommercialTitle: values.agentCommercialTitle,
        cardHolderGovernmentId: values.cardHolderGovernmentId,
        cardHolderPhoneNumber: values.cardHolderPhoneNumber.replaceAll('-', '').replaceAll('_', ''),
        customerName: values.customerName,
        plateNumber: values.plateNumber,
      } as SendConsentFileRequestDto;

      const response = await Api.sendConsentFileLink(requestData);
      if (!response.isSuccess) {
        dispatch(
          showToast({
            message: response.errorMessage ?? 'Hata Oluştu',
            severity: 'error',
          }),
        );
        return;
      }

      dispatch(
        showToast({
          message: 'Muvafakatname başarılıya sms olarak gönderildi. Şimdi muvafakatnameyi doğrulayabilirsiniz.',
          severity: 'success',
        }),
      );
      setShowApproveConsentDialog(true);
      handleClose();
    } catch (error: any) {
      console.log(error.message);
      dispatch(
        showToast({
          message: 'Hata Oluştu',
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }
  };

  const pullGsmQueries = async (govId: any) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const response = await Api.getGsmQueriesFindeks(govId);
      if (response?.data) {
        setGsmQueriesFindeks(response.data);
      }
    } catch (error) {
      console.error('Error fetching GSM queries:', error);
    }
  };

  return (
    <React.Fragment>
      <FormDialog
        formId="sendConsentFile"
        open={open}
        confirmButtonText="Gönder"
        title="Muvafakatname Sözleşmesi Gönder"
        handleClose={() => {
          handleClose();
        }}
        onSubmit={onSubmit}
        showLoading={showLoading}
      >
        <Formik
          onSubmit={onSubmit}
          validationSchema={formSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            cardHolderGovernmentId: '',
            cardHolderPhoneNumber: '',
            customerName: '',
            agentCommercialTitle: '',
            plateNumber: '',
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, values }) => (
            <Form onSubmit={handleSubmit} id="sendConsentFile">
              <Grid container spacing={2} style={{ minWidth: `${isMobile ? 'auto' : '500px'}` }}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>Kart Sahibinin TC No</InputLabel>
                    <OutlinedInput
                      id="cardHolder"
                      type="text"
                      margin="dense"
                      label="Kart Sahibinin TC No"
                      name="cardHolderGovernmentId"
                      value={values.cardHolderGovernmentId}
                      required
                      onChange={(e) => {
                        setFieldValue('cardHolderGovernmentId', e.target.value);

                        if (e.target.value && e.target.value.length > 9) {
                          pullGsmQueries(e.target.value);
                        }
                      }}
                      placeholder="Kart Sahibinin TC No"
                    />
                    <FormHelperText error>{errors.cardHolderGovernmentId}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <FormMaskedTextField
                      placeholder="Kart Sahibinin Telefon No"
                      name="cardHolderPhoneNumber"
                      value={values.cardHolderPhoneNumber}
                      required
                      onChange={handleChange}
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        '-',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        '-',
                        /[0-9]/,
                        /[0-9]/,
                        '-',
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                    />
                    <FormHelperText error>{errors.cardHolderPhoneNumber}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>Noter Sözleşmesindeki Alıcı Adı Soyadı</InputLabel>
                    <OutlinedInput
                      id="customerName"
                      type="text"
                      margin="dense"
                      label="Noter Sözleşmesindeki Alıcı Adı Soyadı"
                      name="customerName"
                      value={values.customerName}
                      required
                      onChange={handleChange}
                      placeholder="Noter Sözleşmesindeki Alıcı Adı Soyadı"
                    />
                    <FormHelperText error>{errors.customerName}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>Şirketinizin Ticari Ünvanı</InputLabel>
                    <OutlinedInput
                      id="agentCommercialTitle"
                      type="text"
                      margin="dense"
                      label="Şirketinizin Ticari Ünvanı"
                      name="agentCommercialTitle"
                      value={values.agentCommercialTitle}
                      required
                      onChange={handleChange}
                      placeholder="Şirketinizin Ticari Ünvanı"
                    />
                    <FormHelperText error>{errors.agentCommercialTitle}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>Satılan Aracın PLakası</InputLabel>
                    <OutlinedInput
                      id="plateNumber"
                      type="text"
                      margin="dense"
                      label="Satılan Aracın PLakası"
                      name="plateNumber"
                      value={values.plateNumber}
                      required
                      onChange={handleChange}
                      placeholder="Satılan Aracın PLakası"
                    />
                    <FormHelperText error>{errors.plateNumber}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormDialog>

      <ApproveConsentFileDialog
        paymentTransactionId={paymentTransactionId}
        open={showApproveConsentDialog}
        handleClose={() => setShowApproveConsentDialog(false)}
      />
    </React.Fragment>
  );
};

export default SendConsentFileDialog;
